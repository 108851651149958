import React from 'react';

import { PreviewDeviceMeta } from 'src/api/types/paywall.types';
import { useAppSelector } from 'src/hooks/redux.hooks';
import styled from 'styled-components';

import { colorBlack, colorNegative } from '../../variables';

type DesktopProps = {
  className?: string;
  children: JSX.Element | JSX.Element[];
  inFocusedState: boolean;
};

const Wrapper = styled.div`
  height: fit-content;
  width: fit-content;
  background-color: ${colorBlack};
  border-radius: 4px;
  padding: 6px;
  margin: 0px auto;
  z-index: 2;
  box-shadow: 0 35px 60px -24px rgba(0 0 0 / 0.4);
  user-select: none;
`;

const Body = styled.div`
  width: fit-content;
  height: fit-content;
  background-color: #474746;
  border-radius: 3px;
  position: relative;
  padding: 3px;
  z-index: 2;
  cursor: pointer;
`;

const DesktopView = styled.div`
  width: fit-content;
  height: fit-content;
  background-color: ${colorNegative};
  position: relative;
  overflow: hidden;
  border-radius: 2px;
`;

export const DesktopInner = styled.div<{ height: number; width: number }>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  flex-direction: column;
  align-items: center;
  justify-content: initial;
  overflow: hidden;
  display: flex;
`;

export default function Desktop({ children, ...props }: DesktopProps) {
  const { currentDevice } = useAppSelector(({ paywallBuilder }) => ({
    currentDevice: paywallBuilder.previewDevice,
  }));
  const currentDeviceMeta = PreviewDeviceMeta[currentDevice];

  return (
    <Wrapper>
      <Body className="namipreviewer desktop">
        <DesktopView>
          <DesktopInner
            height={currentDeviceMeta.viewportHeight}
            width={currentDeviceMeta.viewportWidth}
          >
            {children}
          </DesktopInner>
        </DesktopView>
      </Body>
    </Wrapper>
  );
}
