import React, { ReactNode } from 'react';

import { SettingOutlined, StarFilled, SyncOutlined } from '@ant-design/icons';
import { AppsOutlined } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { Avatar, Button, Empty, List, Space, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { UserPermissionType } from 'src/api/types/main.types';
import SectionTitle from 'src/components/SectionTitle';
import { useAppContext } from 'src/hooks';
import QueryKeys from 'src/hooks/queries/queryKeys';
import { useActions } from 'src/hooks/redux.hooks';
import PaginationSlice from 'src/redux/PaginationSlice';
import { defaultImage } from 'src/services/helpers';
import { namiYellow } from 'src/variables';
import styled from 'styled-components';

const StyledList = styled(List)`
  ul.ant-list-item-action {
    text-align: center;

    li {
      width: 50%;
    }
  }
`;

export default function OrgsPage() {
  const context = useAppContext();
  const history = useHistory();
  const orgs = context.user?.permissions;
  const currentOrg = context.selectedOrg;
  const queryClient = useQueryClient();
  const { setPaywallsPage } = useActions(PaginationSlice.actions);
  const defaultOrgId =
    context.user?.default_org_member && context.user.default_org_member.org
      ? context.user?.default_org_member?.org.id
      : undefined;

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <SectionTitle>Manage Organizations</SectionTitle>
      {!orgs?.length && <Empty />}
      <StyledList itemLayout="vertical" bordered={true}>
        {orgs?.map((org) => {
          return (
            <List.Item actions={getOrgActions(org)} key={org.id}>
              <List.Item.Meta
                title={getOrgName(org)}
                avatar={<Avatar src={defaultImage(org.name)} />}
                description={<>{getRoleDescription(org)}</>}
              />
            </List.Item>
          );
        })}
      </StyledList>
    </Space>
  );

  function getOrgActions(org: UserPermissionType): Array<ReactNode> {
    if (org.id === currentOrg?.id) {
      return [
        <Button
          type="text"
          icon={<SettingOutlined />}
          onClick={() => history.push(`/settings/?tab=org`)}
        >
          Settings
        </Button>,
        <Button
          type="text"
          icon={
            <span role="img" className="anticon">
              <AppsOutlined style={{ fontSize: 16 }} />
            </span>
          }
          onClick={() => history.push(`/settings/?tab=apps`)}
        >
          Manage Apps
        </Button>,
      ];
    }
    return [
      <Button
        type="text"
        icon={<SyncOutlined />}
        onClick={() => setDefaultOrg(org)}
      >
        Switch to this Org
      </Button>,
    ];
  }

  function getOrgName(org: UserPermissionType): React.ReactNode {
    return (
      <Space direction="horizontal">
        {org.name}
        {defaultOrgId === org.id && (
          <Tooltip title="You will be logged into this org by default">
            <StarFilled style={{ color: namiYellow }} />
          </Tooltip>
        )}
      </Space>
    );
  }

  function getRoleDescription(org: UserPermissionType): string {
    if (org.roles) return org.roles[0].display_name;
    return 'Viewer';
  }

  function setDefaultOrg(org: UserPermissionType) {
    context.updateSelectedOrg(org.id);
    setPaywallsPage(1);
    queryClient.invalidateQueries([QueryKeys.apps]);
  }
}
