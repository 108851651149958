import React, { useCallback, useEffect, useMemo } from 'react';

import {
  CloseOutlined,
  ForkOutlined,
  InfoCircleOutlined,
  LockFilled,
  PlusOutlined,
  SearchOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { LaunchSharp } from '@mui/icons-material';
import { uuid4 } from '@sentry/utils';
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { useParams } from 'react-router-dom';
import {
  TPaywallTemplate,
  TTemplateCapability,
} from 'src/api/types/paywallTemplate.types';
import Responsive from 'src/components/Responsive/Responsive';
import styled from 'styled-components';

import {
  CampaignSegmentPayloadType,
  CampaignSegmentType,
} from '../../../../api/types/campaign.types';
import {
  PaywallFormType,
  PaywallType,
  TDevice,
} from '../../../../api/types/paywall.types';
import { backgroundColor } from '../../../../components/PaywallPreview/css';
import ABTestingWebPaywall from '../../../../components/WebPaywalls/ABTestingWebPaywall';
import { useAppContext, useBooleanState } from '../../../../hooks';
import {
  useAllPaywallsQuery,
  usePaywallRedirect,
} from '../../../../hooks/queries/paywall.hooks';
import {
  findBackgroundColor,
  findBackgroundImage,
} from '../../../../utils/paywall';
import {
  namiBrightBlue,
  namiDarkGray,
  namiLightGray,
  namiLightOrange,
} from '../../../../variables';
import { UpgradeButton } from '../../paywalls/PaywallBuilder/editor/inputs/FontSelect';
import { formFactorIcons } from '../../paywalls/PaywallTable';
import {
  pullOutDeviceOrientation,
  pullOutFormFactor,
} from '../../paywalls/utils/functions';
import CampaignCard from './CampaignCard';
import { TCampaignDetailParams } from './params.types';

type SegmentFieldSectionProps = {
  anonymous: boolean;
  loading?: boolean;
  segments: Record<string, CampaignSegmentType | CampaignSegmentPayloadType>;
  onChange: (
    segments: Record<string, CampaignSegmentType | CampaignSegmentPayloadType>
  ) => void;
  segmentErrorMessages: string[];
  formFactorRule: TDevice | null;
  setPaywallLocked: (value: boolean) => void;
  setPaywallFFMismatch: (value: boolean) => void;
  openCapabilitiesWebPaywall: () => void;
  campaignLive?: boolean;
};

const LockIcon = styled(LockFilled)`
  color: ${namiBrightBlue};
`;

const PaywallSelect = styled(Select)`
  height: 44px;

  .ant-select-selector {
    height: 100% !important;
    padding: 5px !important;
  }

  .ant-select-selection-search-input {
    height: 100% !important;
  }
`;

const StyledSplitInput = styled(InputNumber)`
  div.ant-input-number.ant-input-number-lg.ant-input-number-in-form-item {
    height: 44px;
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 12px !important;
`;

export const PaywallBackground = styled(Image)`
  object-fit: cover;
  ${({ color }) => (color ? backgroundColor(color) : '')}
`;

const PaywallName = styled(Typography.Text)`
  font-weight: 500;
  display: block !important;
  width: max-content;
  color: ${({ disabled }) => (disabled ? '#4c545a' : 'initial')};
`;

const PaywallDescription = styled(Typography.Text)`
  font-size: 13px;
  color: ${({ disabled }) => (disabled ? '#4c545a' : 'initial')};
`;

const PaywallEditLink = styled(Button)`
  color: ${namiDarkGray};
  height: 44px;
`;

const SegmentDeleteButton = styled(Button)`
  color: ${namiDarkGray};
  height: 44px;
`;

const SmallSegmentDeleteButton = styled(Button)`
  color: ${namiDarkGray};
  display: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  float: right;

  span:nth-child(2) {
    margin-left: 4px !important;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 18px 0px !important;
`;

export default function SegmentFieldSection({
  anonymous,
  loading,
  segments,
  onChange,
  segmentErrorMessages,
  formFactorRule,
  setPaywallLocked,
  setPaywallFFMismatch,
  openCapabilitiesWebPaywall,
  campaignLive,
}: SegmentFieldSectionProps) {
  const ruleId = useParams<TCampaignDetailParams>().campaignID;
  const redirectToPaywall = usePaywallRedirect();
  const { userHasEntitlement, planHasEntitlement } = useAppContext();
  const paywallsQuery = useAllPaywallsQuery();
  const [isUpgradeOpen, openUpgrade, closeUpgrade] = useBooleanState(false);

  const paywalls = useMemo((): { [key: string]: PaywallFormType } => {
    return (paywallsQuery.data || []).reduce((output, paywall) => {
      if (paywall.type === 'component') {
        return {
          ...output,
          [paywall.id]: {
            ...paywall,
            form_factor: pullOutFormFactor(paywall.template),
            form_factor_orientation: pullOutDeviceOrientation(paywall.template),
            unlocked: checkIfPaywallActive(paywall.template),
          },
        };
      }
      return output;
    }, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paywallsQuery.data, formFactorRule]);

  const enabledCreate = Object.values(segments).length < 10;

  const getPaywallGated = useCallback(
    (paywallId: string | null): boolean => {
      if (paywallId && paywallId in paywalls) {
        return !paywalls[paywallId].unlocked;
      }
      return false;
    },
    [paywalls]
  );

  const getFormFactorForPaywall = useCallback(
    (paywallId: string | null): TDevice => {
      if (paywallId && paywallId in paywalls)
        return paywalls[paywallId].form_factor;
      return 'phone';
    },
    [paywalls]
  );

  useEffect(() => {
    const anyPaywallGated = Object.entries(segments).some(([_id, segment]) =>
      getPaywallGated(segment.paywall)
    );
    setPaywallLocked(anyPaywallGated);
  }, [segments, getPaywallGated, setPaywallLocked]);

  useEffect(() => {
    const newMismatch = Object.entries(segments).some(
      ([_id, segment]) =>
        getFormFactorForPaywall(segment.paywall) !== formFactorRule
    );
    setPaywallFFMismatch(!!formFactorRule && newMismatch);
  }, [segments, formFactorRule, getFormFactorForPaywall, setPaywallFFMismatch]);

  const hasABAccess = ['app.campaign.paywall_segmentation.basic'].every(
    planHasEntitlement
  );
  const hasMVAccess = ['app.campaign.paywall_segmentation.multivariant'].every(
    planHasEntitlement
  );

  const userCanEditCampaign = userHasEntitlement('app.campaign.update');
  const liveABTest = campaignLive && Object.keys(segments).length > 1;

  const splitEditingDisabled =
    !userCanEditCampaign || Object.keys(segments).length < 2 || liveABTest;

  const cardHeader = useMemo(() => {
    if (liveABTest) {
      return (
        <Space direction="horizontal">
          Paywalls
          <Tooltip title="You cannot edit paywall segments in a running AB test. Pause the campaign to change paywalls.">
            <InfoCircleOutlined
              style={{ fontSize: 13, color: namiLightOrange }}
            />
          </Tooltip>
        </Space>
      );
    }
    return 'Paywalls';
  }, [liveABTest]);

  const tooltipTitle: React.ReactNode = (
    <>
      <span>This paywall uses Premium features.</span>
      <UpgradeButton
        size="small"
        type="link"
        icon={<UnlockOutlined />}
        onClick={openCapabilitiesWebPaywall}
      >
        Upgrade your Account.
      </UpgradeButton>
    </>
  );

  const paywallOptions = Object.values(paywalls).reduce((output, paywall) => {
    if (!!formFactorRule && formFactorRule !== paywall.form_factor)
      return output;
    return [
      ...output,
      {
        key: paywall.id,
        value: paywall.id,
        name: paywall.name,
        label: (
          <Space direction="horizontal" size={8}>
            <PaywallBackground
              height={32}
              preview={false}
              width={32}
              color={findBackgroundColor(paywall)}
              src={findBackgroundImage(paywall) || undefined}
              style={{
                border: `1px solid ${namiLightGray}`,
              }}
            />
            <PaywallName disabled={!paywall.unlocked}>
              {paywall.name}
            </PaywallName>
            {formFactorIcons[paywall.form_factor_orientation]}
            {!paywall.unlocked && (
              <Tooltip title={tooltipTitle}>
                <LockFilled style={{ color: namiBrightBlue }} />
              </Tooltip>
            )}
            {paywall.description && ' • '}
            <PaywallDescription ellipsis={true} disabled={!paywall.unlocked}>
              {paywall.description}
            </PaywallDescription>
          </Space>
        ),
        disabled: !paywall.unlocked,
      },
    ];
  }, [] as Array<{ key: string; value: string; name: string; label: JSX.Element; disabled: boolean }>);

  return (
    <CampaignCard
      loading={loading}
      title={cardHeader}
      type="inner"
      id="paywalls"
    >
      <Responsive size="mdUp">
        {Object.entries(segments).map(([key, segment]) =>
          renderSegmentRow('mdUp', key, segment)
        )}
      </Responsive>
      <Responsive size="mdDown">
        {Object.entries(segments).map(([key, segment], index) =>
          renderSegmentRow('mdDown', key, segment, index)
        )}
      </Responsive>
      {!anonymous && (
        <Form.Item>
          {enabledCreate && Object.keys(segments).length < 2 && (
            <Button
              ghost
              disabled={anonymous || !userCanEditCampaign}
              icon={hasABAccess ? <ForkOutlined /> : <LockIcon />}
              type="primary"
              onClick={addNewSegment}
              className="intercom-campaignAddABTest"
              style={{ marginTop: '12px' }}
              size="small"
            >
              Create A/B Test
            </Button>
          )}
          {enabledCreate && Object.keys(segments).length > 1 && (
            <Button
              ghost
              disabled={anonymous || !userCanEditCampaign || liveABTest}
              icon={hasMVAccess ? <PlusOutlined /> : <LockIcon />}
              type="primary"
              onClick={addNewSegment}
              className="intercom-campaignAddMVTest"
              size="small"
            >
              Add Segment
            </Button>
          )}
        </Form.Item>
      )}
      <Row gutter={[16, 16]} style={{ marginTop: 4 }}>
        {segmentErrorMessages.length > 0 && (
          <Col md={18}>
            <Typography.Text type="danger">
              {segmentErrorMessages.join('\n')}
            </Typography.Text>
          </Col>
        )}
      </Row>
      <ABTestingWebPaywall visible={isUpgradeOpen} onCancel={closeUpgrade} />
    </CampaignCard>
  );

  function renderSegmentRow(
    size: 'mdUp' | 'mdDown',
    key: string,
    segment: CampaignSegmentPayloadType | CampaignSegmentType,
    index?: number
  ) {
    if (size === 'mdDown')
      return (
        <span key={key}>
          <Row gutter={[0, 0]}>
            <Col flex="auto">
              <StyledFormItem className="intercom-campaignPaywallSelector">
                {paywallsQuery.isFetching ? (
                  <Skeleton paragraph={false} active title={{ width: '90%' }} />
                ) : (
                  <PaywallSelect
                    suffixIcon={<SearchOutlined />}
                    showSearch
                    disabled={!userCanEditCampaign || liveABTest}
                    onChange={(value: any) =>
                      handlePaywallChange(key, value as string | undefined)
                    }
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.name || '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    placeholder="Search by Paywall name"
                    value={segment.paywall}
                    options={paywallOptions}
                    status={
                      segmentErrorMessages.length > 0 ? 'error' : undefined
                    }
                  />
                )}
              </StyledFormItem>
            </Col>
          </Row>
          <Row gutter={[0, 0]}>
            <Col span={12}>
              <Space direction="horizontal" size={8}>
                <span>Split:</span>
                <InputNumber
                  disabled={splitEditingDisabled}
                  onChange={(value) => handleSplitChange(key, value as number)}
                  addonAfter="%"
                  value={segment.split || 0}
                  placeholder="Split"
                  min={0}
                  max={100}
                  step={1}
                  status={
                    !splitEditingDisabled && segmentErrorMessages.length > 0
                      ? 'error'
                      : undefined
                  }
                />
              </Space>
            </Col>
            <Col span={12}>{renderSmallSegmentDeleteLink(key)}</Col>
          </Row>
          <StyledDivider
            style={{
              visibility:
                (index || 0) + 1 >= Object.entries(segments).length
                  ? 'hidden'
                  : 'initial',
            }}
          />
        </span>
      );
    return (
      <Row gutter={[16, 16]} key={key}>
        <Col span={20}>
          <Row gutter={[0, 0]}>
            <Col flex="auto">
              <StyledFormItem className="intercom-campaignPaywallSelector">
                {paywallsQuery.isFetching ? (
                  <Skeleton paragraph={false} active title={{ width: '90%' }} />
                ) : (
                  <PaywallSelect
                    suffixIcon={<SearchOutlined />}
                    showSearch
                    disabled={!userCanEditCampaign || liveABTest}
                    onChange={(value: any) =>
                      handlePaywallChange(key, value as string | undefined)
                    }
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.name || '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    placeholder="Search by Paywall name"
                    value={segment.paywall}
                    options={paywallOptions}
                    status={
                      segmentErrorMessages.length > 0 ? 'error' : undefined
                    }
                  />
                )}
              </StyledFormItem>
            </Col>
            <Col flex="7em">
              <StyledFormItem>
                <StyledSplitInput
                  disabled={splitEditingDisabled}
                  onChange={(value) => handleSplitChange(key, value as number)}
                  addonAfter="%"
                  value={segment.split || 0}
                  placeholder="Split"
                  min={0}
                  max={100}
                  step={1}
                  size="large"
                  style={{ width: '7em', transform: 'translateX(-1px)' }}
                  status={
                    !splitEditingDisabled && segmentErrorMessages.length > 0
                      ? 'error'
                      : undefined
                  }
                />
              </StyledFormItem>
            </Col>
          </Row>
        </Col>
        <Col md={4} sm={12}>
          {renderSegmentDeleteLink(key)}
          {renderPaywallEditLink(segment.paywall)}
        </Col>
      </Row>
    );
  }

  function handlePaywallChange(key: string, paywallId?: string): void {
    const segment = { ...segments[key], paywall: paywallId || null };
    onChange({ ...segments, [key]: segment });
  }

  function recalculateSplits(
    curSegments: Record<
      string,
      CampaignSegmentPayloadType | CampaignSegmentType
    >,
    addingSegment: boolean
  ): Record<string, CampaignSegmentPayloadType | CampaignSegmentType> {
    const numberSegments =
      Object.keys(curSegments).length + (addingSegment ? 1 : 0);
    const baseSplit = Math.floor(100 / numberSegments);
    const remainder = 100 % numberSegments;
    const newSegments = Object.entries(curSegments).reduce(
      (output, [itemKey, item], index) => {
        let split = baseSplit;
        if (index === 0) split = split + remainder;
        return { ...output, [itemKey]: { ...item, split } };
      },
      {}
    );
    return newSegments;
  }

  function handleSplitChange(key: string, value: number): void {
    const newSegments = Object.entries(segments).reduce(
      (output, [itemKey, item]) => {
        const split = itemKey === key ? value : item.split;
        return { ...output, [itemKey]: { ...item, split } };
      },
      {}
    );
    onChange(newSegments);
  }

  function checkIfPaywallActive(paywall: TPaywallTemplate): boolean {
    const capabilities: TTemplateCapability[] =
      paywall['ui.capabilities'] || [];

    return capabilities.every((capability) =>
      planHasEntitlement(`app.paywall.capabilities.${capability}`)
    );
  }

  function addNewSegment() {
    const existingSegments = Object.keys(segments);
    if (!hasABAccess) return openUpgrade();
    if (!hasMVAccess && existingSegments.length > 1) return openUpgrade();
    const newSegment = {
      rule: ruleId,
      paywall: null,
      split: calculateSplit(true, existingSegments.length),
    };
    onChange({ ...recalculateSplits(segments, true), [uuid4()]: newSegment });
  }

  function deleteSegment(segmentKey: string): void {
    const newSegments = Object.entries(segments).filter(
      ([key, _segment]) => key !== segmentKey
    );
    onChange(recalculateSplits(Object.fromEntries(newSegments), false));
  }

  function renderPaywallEditLink(paywallId?: string | null) {
    return (
      <Tooltip title="Go to Paywall" align={{ offset: [0, 5] }}>
        <PaywallEditLink
          type="link"
          style={!paywallId ? { display: 'none' } : undefined}
          onClick={() =>
            paywallId &&
            redirectToPaywall({
              type: 'component',
              id: paywallId,
            } as PaywallType)
          }
          icon={
            <LaunchSharp
              style={{ fontSize: 16, transform: 'translateY(2px)' }}
            />
          }
        ></PaywallEditLink>
      </Tooltip>
    );
  }

  function renderSegmentDeleteLink(key: string) {
    return (
      <Tooltip title="Delete Segment">
        <SegmentDeleteButton
          type="link"
          icon={<CloseOutlined />}
          onClick={() => deleteSegment(key)}
          style={
            Object.keys(segments).length < 2 ? { display: 'none' } : undefined
          }
          disabled={liveABTest}
        />
      </Tooltip>
    );
  }

  function renderSmallSegmentDeleteLink(key: string) {
    return (
      <SmallSegmentDeleteButton
        type="link"
        icon={<CloseOutlined />}
        onClick={() => deleteSegment(key)}
        disabled={Object.keys(segments).length < 2 || liveABTest}
      >
        Remove
      </SmallSegmentDeleteButton>
    );
  }

  function calculateSplit(
    addingSegment: boolean,
    currentNumSegments: number
  ): number {
    const segmentNumber = addingSegment
      ? currentNumSegments + 1
      : currentNumSegments - 1;
    return Math.floor(100 / segmentNumber);
  }
}
