import React from 'react';

import Icon, {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  ReadOutlined,
} from '@ant-design/icons';
import {
  AlignHorizontalCenter,
  AlignHorizontalLeft,
  AlignHorizontalRight,
  AlignVerticalBottom,
  AlignVerticalCenter,
  AlignVerticalTop,
} from '@mui/icons-material';
import { Form, InputNumber, Radio, Space, Typography } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { namiMediumGray } from 'src/variables';
import styled from 'styled-components';

import {
  TComponent,
  TField,
} from '../../../../../api/types/paywallTemplate.types';
import { ReactComponent as HorizontalIcon } from '../../../../../images/carbon-view-horizontal.svg';
import { ReactComponent as VerticalIcon } from '../../../../../images/carbon-view-vertical.svg';
import { ReactComponent as FillWidthIcon } from '../../../../../images/material-symbols-light_width.svg';
import { FieldObject } from '../../utils/formFieldBuilding';
import CarouselInputSelector from './inputs/CarouselInputSelector';
import CarouselSlideSelector from './inputs/CarouselSlideSelector';
import CollapseOpenToggleSwitch from './inputs/CollapseOpenToggleSwitch';
import DateTimeFormatInput from './inputs/DateTimeFormatInput';
import DropShadowCreator from './inputs/DropShadowCreator';
import FontSelect from './inputs/FontSelect';
import GradientPicker from './inputs/GradientPicker';
import IconSelect from './inputs/IconSelect';
import ImageButton from './inputs/ImageButton';
import ImagePicker from './inputs/ImagePicker';
import InputLabel from './inputs/InputLabel';
import ListContainerComponentEditor from './inputs/ListContainerComponentEditor';
import OnTapSelector from './inputs/OnTapSelector';
import ProductVariableSelector from './inputs/ProductVariableSelector';
import PtPxInput from './inputs/PtPxSelector';
import RoundBorderSelector from './inputs/RoundBorderSelector';
import SafeAreaDisplay from './inputs/SafeAreaDisplay';
import TextAreaInput from './inputs/TextAreaInput';
import TextInput from './inputs/TextInput';
import ToggleSwitch from './inputs/ToggleSwitch';
import HeightWidthInput from './inputs/WidthInput';

type LocalFieldObject = Omit<FieldObject, 'variable'>;
type SmartInputProps = LocalFieldObject & {
  defaultValue?: string | number;
  onChange: (value: any) => void;
  label: string;
  controlled?: boolean;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
  component?: TComponent;
};

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 12px;
`;

const MULTILINE_FIELDS = new Set(['textArea', 'splitTextArea']);

const MarkdownHint = styled(Typography.Text)`
  float: right;
  margin-bottom: 10px;
`;

const MarkdownLink = styled.a.attrs({
  href: 'https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/editable-properties#text',
  target: '_blank',
  rel: 'noreferrer noopener',
})`
  color: inherit !important;
`;

const FormHint = styled(Typography.Text)`
  float: right;
  margin-bottom: 10px;
  color: ${namiMediumGray} !important;
`;

const inputMap = {
  carouselSlides: CarouselSlideSelector,
  fontSelect: FontSelect,
  color: (props) => <GradientPicker {...props} showGradientControls={false} />,
  colorGradient: (props) => (
    <GradientPicker {...props} showGradientControls={true} />
  ),
  iconSelect: IconSelect,
  toggle: ToggleSwitch,
  collapseOpenToggle: CollapseOpenToggleSwitch,
  listContainerComponent: ListContainerComponentEditor,
  image: ImageButton,
  imagePicker: (props) => <ImagePicker {...props} variant="image" />,
  fallbackImagePicker: (props) => (
    <ImagePicker {...props} variant="videoFallbackImage" />
  ),
  dropShadow: DropShadowCreator,
  width: (props) => <HeightWidthInput {...props} variant="width" />,
  height: (props) => <HeightWidthInput {...props} variant="height" />,
  widthFillFixed: (props) => (
    <HeightWidthInput {...props} variant="width" includeFit={false} />
  ),
  heightFillFixed: (props) => (
    <HeightWidthInput {...props} variant="height" includeFit={false} />
  ),
  safeArea: SafeAreaDisplay,
  ptPxSelect: PtPxInput,
  roundBorders: RoundBorderSelector,
  productVariableSelector: (props) => (
    <ProductVariableSelector {...props} conditionAttribute={false} />
  ),
  conditionalProductVariableSelector: (props) => (
    <ProductVariableSelector {...props} conditionAttribute={true} />
  ),
  url: (props) => <TextInput {...props} url={true} />,
  carouselTextInputSelector: (props) => (
    <CarouselInputSelector {...props} variant="text" />
  ),
  carouselImageInputSelector: (props) => (
    <CarouselInputSelector {...props} variant="image" />
  ),
  dateTimeFormat: DateTimeFormatInput,
  onTap: OnTapSelector,
} as { [key in TField]: React.FunctionComponent<SmartInputProps> };

export default function SmartInput({
  defaultValue,
  onChange,
  controlled = false,
  ...field
}: SmartInputProps) {
  const CustomInput = inputMap[field.type];
  const inputProps = { defaultValue, onChange, controlled, ...field };
  if (!!CustomInput) return <CustomInput {...inputProps} />;

  const placeholder = field.placeholder || field.name;

  const markdownHint = field.markdownHint && (
    <MarkdownHint>
      <MarkdownLink>
        <ReadOutlined /> Add <strong>**Bold**</strong> or <i>*Italic*</i> Text
        with Markdown
      </MarkdownLink>
    </MarkdownHint>
  );

  const textHint = field.hint && <FormHint>{field.hint}</FormHint>;

  const itemProps = {
    help: markdownHint || textHint,
    name: controlled ? undefined : field.variable,
    label: <InputLabel {...field} />,
  };
  const value = controlled ? field.value : undefined;

  if (field.type === 'number') {
    return (
      <StyledFormItem {...itemProps}>
        <InputNumber
          value={value as number | undefined}
          disabled={!field.editable}
          placeholder={placeholder}
          onChange={handleNumberChange}
          min={field.minLimit || 0}
          max={field.maxLimit || 100}
          style={{ width: '100%' }}
        />
      </StyledFormItem>
    );
  }
  if (field.type === 'bigNumber') {
    return (
      <StyledFormItem {...itemProps}>
        <InputNumber
          value={value as number | undefined}
          disabled={!field.editable}
          placeholder={placeholder}
          onChange={handleNumberChange}
          min={field.minLimit || 0}
          max={field.maxLimit || undefined}
          style={{ width: '100%' }}
        />
      </StyledFormItem>
    );
  }
  if (field.type === 'alignment') {
    return (
      <StyledFormItem {...itemProps}>
        <Radio.Group
          disabled={!field.editable}
          value={value}
          onChange={handleInputChange}
        >
          <Radio.Button value="left" style={{ padding: '0 12px' }}>
            <AlignLeftOutlined />
          </Radio.Button>
          <Radio.Button value="center" style={{ padding: '0 12px' }}>
            <AlignCenterOutlined />
          </Radio.Button>
          <Radio.Button value="right" style={{ padding: '0 12px' }}>
            <AlignRightOutlined />
          </Radio.Button>
        </Radio.Group>
      </StyledFormItem>
    );
  }
  if (field.type === 'verticalAlignment') {
    return (
      <StyledFormItem {...itemProps}>
        <Radio.Group
          disabled={!field.editable}
          value={value}
          onChange={handleInputChange}
        >
          <Radio.Button value="top" style={{ padding: '0 12px' }}>
            <AlignVerticalTop
              style={{ fontSize: 14, transform: 'translateY(2px)' }}
            />
          </Radio.Button>
          <Radio.Button value="center" style={{ padding: '0 12px' }}>
            <AlignVerticalCenter
              style={{ fontSize: 14, transform: 'translateY(2px)' }}
            />
          </Radio.Button>
          <Radio.Button value="bottom" style={{ padding: '0 12px' }}>
            <AlignVerticalBottom
              style={{ fontSize: 14, transform: 'translateY(2px)' }}
            />
          </Radio.Button>
        </Radio.Group>
      </StyledFormItem>
    );
  }
  if (field.type === 'horizontalAlignment') {
    return (
      <StyledFormItem {...itemProps}>
        <Radio.Group
          disabled={!field.editable}
          value={value}
          onChange={handleInputChange}
        >
          <Radio.Button value="left" style={{ padding: '0 12px' }}>
            <AlignHorizontalLeft
              style={{ fontSize: 14, transform: 'translateY(2px)' }}
            />
          </Radio.Button>
          <Radio.Button value="center" style={{ padding: '0 12px' }}>
            <AlignHorizontalCenter
              style={{ fontSize: 14, transform: 'translateY(2px)' }}
            />
          </Radio.Button>
          <Radio.Button value="right" style={{ padding: '0 12px' }}>
            <AlignHorizontalRight
              style={{ fontSize: 14, transform: 'translateY(2px)' }}
            />
          </Radio.Button>
        </Radio.Group>
      </StyledFormItem>
    );
  }
  if (field.type === 'option') {
    return (
      <StyledFormItem {...itemProps}>
        <Radio.Group
          disabled={!field.editable}
          value={value}
          onChange={handleInputChange}
        >
          {field.options!.map(({ label, value }) => (
            <Radio.Button
              key={value}
              value={value}
              style={{ fontSize: 13, padding: '0 12px' }}
            >
              {label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </StyledFormItem>
    );
  }

  if (field.multiline || MULTILINE_FIELDS.has(field.type)) {
    return (
      <TextAreaInput
        defaultValue={defaultValue}
        onChange={onChange}
        controlled={controlled}
        {...field}
      />
    );
  }

  if (field.type === 'direction') {
    const options = [
      {
        value: 'horizontal',
        label: (
          <Space size={4}>
            <Icon
              component={VerticalIcon}
              style={{ fontSize: 16, transform: 'translateY(3px)' }}
            />
            <>Horizontal</>
          </Space>
        ),
      },
      {
        value: 'vertical',
        label: (
          <Space size={4}>
            <Icon
              component={HorizontalIcon}
              style={{ fontSize: 16, transform: 'translateY(3px)' }}
            />
            <>Vertical</>
          </Space>
        ),
      },
    ];
    return (
      <StyledFormItem {...itemProps}>
        <Radio.Group
          disabled={!field.editable}
          value={value}
          onChange={handleInputChange}
        >
          {options.map(({ label, value }) => (
            <Radio.Button
              key={value}
              value={value}
              style={{ fontSize: 13, padding: '0 12px' }}
            >
              {label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </StyledFormItem>
    );
  }

  if (field.type === 'imageCropping') {
    const options = [
      {
        value: 'fit',
        label: (
          <Space size={4}>
            <Icon
              component={FillWidthIcon}
              style={{ fontSize: 20, transform: 'translateY(3px)' }}
            />
            <>Fit</>
          </Space>
        ),
      },
      {
        value: 'fill',
        label: (
          <Space size={4}>
            <Icon
              component={FillWidthIcon}
              style={{ fontSize: 20, transform: 'translateY(3px)' }}
              rotate={90}
            />
            <>Fill</>
          </Space>
        ),
      },
    ];
    return (
      <StyledFormItem {...itemProps}>
        <Radio.Group
          disabled={!field.editable}
          value={value}
          onChange={handleInputChange}
        >
          {options.map(({ label, value }) => (
            <Radio.Button
              key={value}
              value={value}
              style={{ fontSize: 13, padding: '0 12px' }}
            >
              {label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </StyledFormItem>
    );
  }

  return (
    <TextInput
      defaultValue={defaultValue}
      onChange={onChange}
      controlled={controlled}
      {...field}
    />
  );

  function handleInputChange(
    e: React.ChangeEvent<HTMLInputElement> | RadioChangeEvent
  ): void {
    const { value } = e.target;
    if (!field.editable || !isWithinMinMax(field, value.length)) return;
    onChange(value);
  }

  function handleNumberChange(value: number | null): void {
    if (value === null) return;
    if (!field.editable || !isWithinMinMax(field, value)) return;
    onChange(+value);
  }
}

function isWithinMinMax(field: LocalFieldObject, value: number): boolean {
  return (
    (!field.maxLimit || field.maxLimit >= value) &&
    (!field.minLimit || field.minLimit <= value)
  );
}
