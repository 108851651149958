import React from 'react';

import { Form, Input } from 'antd';

import { FieldObject } from '../../../utils/formFieldBuilding';
import InputLabel from './InputLabel';

type DateTimeFormatProps = Omit<FieldObject, 'variable' | 'value'> & {
  defaultValue?: string | number;
  onChange: (value: string | null) => void;
  label: string;
  controlled?: boolean;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
  value: string;
};

export default function DateTimeFormatInput({
  defaultValue,
  onChange,
  label: _,
  name,
  ...field
}: DateTimeFormatProps) {
  const value = field.value;
  return (
    <Form.Item
      label={<InputLabel name={name} {...field} />}
      tooltip={
        <>
          Parse dates using Unicode tokens.{' '}
          <a
            href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/editable-properties"
            target="_blank"
            rel="noreferrer"
          >
            Learn more.
          </a>
        </>
      }
    >
      <Input
        value={value}
        disabled={!field.editable}
        placeholder="Select a date format string"
        onChange={handleInputChange}
        status={checkForValidUnicodeTokens(value) ? undefined : 'error'}
      />
    </Form.Item>
  );

  function checkForValidUnicodeTokens(value: string): boolean {
    const unicodeRegex = /^[dDMEyhmsa\s\\.:\\/,-]*$/;
    return unicodeRegex.test(value);
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = e.target;
    if (!field.editable) return;
    onChange(value);
  }
}
