import React from 'react';

import { useParams } from 'react-router-dom';

import CDPIntegrationDetailPage from './CDPIntegrationDetailPage';
import StreamPlatformIntegrationDetailPage from './StreamPlatformIntegrationDetailPage';
import { TIntegrationParams } from './params.types';

const CDP_TYPES = new Set([
  'generic',
  'adobe_ec',
  'amplitude',
  'adobe_s3',
  'custom',
]);

export default function IntegrationDetailPage() {
  const params = useParams<TIntegrationParams>();

  if (CDP_TYPES.has(params.type)) return <CDPIntegrationDetailPage />;
  return <StreamPlatformIntegrationDetailPage />;
}
