import React from 'react';

import { PlusCircleOutlined, ReadOutlined } from '@ant-design/icons';
import { Button, Popover, Tooltip } from 'antd';
import { namiLightGray } from 'src/variables';
import styled from 'styled-components';

type SmartTextTag = {
  label: string;
  value: string;
  hint: string;
};

type SmartTextTagCloudProps = {
  handleChange: (value: any) => void;
};

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  padding: 4px;
  text-align: center;
  justify-content: center;
`;

export const CustomTag = styled.span`
  border: 1px solid ${namiLightGray};
  border-radius: 15px !important;
  background: #fafafa;
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
  cursor: pointer;
  padding: 1px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  :hover {
    background: #ffffff;
    border: 1px solid rgba(19, 116, 222, 0.5);
  }
`;

export default function SmartTextTagCloud({
  handleChange,
}: SmartTextTagCloudProps) {
  const smartText: SmartTextTag[] = [
    {
      label: 'Price',
      value: `\${sku.price}`,
      hint: 'Base product price',
    },
    {
      label: 'Duration',
      value: `\${sku.duration}`,
      hint: "Subscription duration, such as '1 year'",
    },
    {
      label: 'Duration in Months',
      value: `\${sku.durationInMonths}`,
      hint: "Subscription duration converted to months, such as '12 months'",
    },
    {
      label: 'Period',
      value: `\${sku.period}`,
      hint: 'Time unit of duration, such as week, month, or year',
    },
    {
      label: 'Period in Months',
      value: `\${sku.periodInMonths}`,
      hint: 'Time unit of duration translated to months',
    },
    {
      label: 'Period Number',
      value: `\${sku.periodNumber}`,
      hint: 'Number unit of subscription duration, such as 1, 6, or 12',
    },
    {
      label: 'Period Number in Months',
      value: `\${sku.periodNumberInMonths}`,
      hint: "Number unit of subscription duration in months, such as '12' for 1 year",
    },
    {
      label: 'Price per Month',
      value: `\${sku.pricePerMonth}`,
      hint: 'Subscription price converted to months. Product duration must be at least 1 month',
    },
    {
      label: 'Price per Month Rounded',
      value: `\${sku.pricePerMonthRounded}`,
      hint: 'Subscription price converted to months and rounded to nearest dollar amount. Product duration must be at least 1 month. SDK Version 3.1.24+',
    },
    {
      label: 'Price per Week',
      value: `\${sku.pricePerWeek}`,
      hint: 'Subscription price converted to weeks. SDK Version 3.1.21+',
    },
    {
      label: 'Price per Week Rounded',
      value: `\${sku.pricePerWeekRounded}`,
      hint: 'Subscription price converted to weeks and rounded to nearest dollar amount. SDK Version 3.1.24+',
    },
    {
      label: 'Price per Day',
      value: `\${sku.pricePerDay}`,
      hint: 'Subscription price converted to days. SDK Version 3.1.17+',
    },
    {
      label: 'Duration Singular',
      value: `\${sku.durationSingular}`,
      hint: "Subscription duration without pluralization, such as '6 month'",
    },
    {
      label: 'Duration in Months Singular',
      value: `\${sku.durationInMonthsSingular}`,
      hint: "Subscription duration converted to months without pluralization, such as '12 month'",
    },
    {
      label: '% Price Difference:#',
      value: `\${sku.percentagePriceDifferenceFromSku:1}`,
      hint: "The price difference between current SKU and SKU at position #, calculated as relative percentage. E.g. '34%'",
    },
    {
      label: '$ Price Difference:#',
      value: `\${sku.priceDifferenceFromSku:1}`,
      hint: "The price difference between current SKU and SKU at position #, calculated as price per duration. E.g. '$4.50'",
    },
    {
      label: 'Trial Duration',
      value: `\${sku.freeTrialDuration}`,
      hint: "Duration of subscription free trial, such as '3 days'",
    },
    {
      label: 'Trial Period',
      value: `\${sku.freeTrialPeriod}`,
      hint: "Time unit of subscription free trial, such as 'days'",
    },
    {
      label: 'Trial Period Number',
      value: `\${sku.freeTrialPeriodNumber}`,
      hint: 'Number unit of subscription free trial, such as 7',
    },
    {
      label: 'Trial Duration Singular',
      value: `\${sku.freeTrialDurationSingular}`,
      hint: "Subscription free trial duration without plural, such as '3 day'",
    },
    {
      label: 'Intro Price',
      value: `\${sku.introductoryPrice}`,
      hint: 'Subscription introductory offer price',
    },
    {
      label: 'Intro Price per Month',
      value: `\${sku.introductoryPricePerMonth}`,
      hint: 'Subscription introductory offer price  converted to months. Intro duration must be at least 1 month. SDK Version 3.2+',
    },
    {
      label: 'Intro Duration',
      value: `\${sku.introductoryDuration}`,
      hint: 'Duration of subscription introductory offer',
    },
    {
      label: 'Intro Period',
      value: `\${sku.introductoryPeriod}`,
      hint: 'Time unit of subscription introductory offer duration',
    },
    {
      label: 'Intro Period Number',
      value: `\${sku.introductoryPeriodNumber}`,
      hint: 'Number unit of subscription introductory offer duration',
    },
    {
      label: 'Intro Duration Singular',
      value: `\${sku.introductoryDurationSingular}`,
      hint: 'Duration of subscription introductory offer without pluralization',
    },
    {
      label: 'Promo Price:# or id',
      value: `\${sku.promoPrice:0}`,
      hint: 'Subscription promotional offer price. When index # or promo id is present, choose this specific offer out of list.',
    },
    {
      label: 'Promo Phase 2 Price:# or id',
      value: `\${sku.promoPricePhase2:0}`,
      hint: 'Android only. SDK Version 3.2.4+. Promotional offer price for second phase of promo.',
    },
    {
      label: 'Promo Duration:# or id',
      value: `\${sku.promoDuration:0}`,
      hint: 'Duration of subscription promotional offer. When index # or promo id is present, choose this specific offer out of list.',
    },
    {
      label: 'Promo Phase 2 Duration:# or id',
      value: `\${sku.promoDurationPhase2:0}`,
      hint: 'Android only. SDK Version 3.2.4+. Promotional offer duration for second phase of promo.',
    },
    {
      label: 'Promo Period:# or id',
      value: `\${sku.promoPeriod:0}`,
      hint: 'Time unit of subscription promotional offer duration. When index # or promo id is present, choose this specific offer out of list.',
    },
    {
      label: 'Promo Phase 2 Period:# or id',
      value: `\${sku.promoPeriodPhase2:0}`,
      hint: 'Android only. SDK Version 3.2.4+. Promotional offer time unit for second phase of promo.',
    },
    {
      label: 'Promo Period Number:# or id',
      value: `\${sku.promoPeriodNumber:0}`,
      hint: 'Number unit of subscription promotional offer duration. When index # or promo id is present, choose this specific offer out of list.',
    },
    {
      label: 'Promo Phase 2 Period Number:# or id',
      value: `\${sku.promoPeriodNumberPhase2:0}`,
      hint: 'Android only. SDK Version 3.2.4+. Promotional offer number unit for second phase of promo.',
    },
    {
      label: 'Promo Duration Singular:# or id',
      value: `\${sku.promoDurationSingular:0}`,
      hint: 'Duration of subscription promotional offer without pluralization. When index # or promo id is present, choose this specific offer out of list.',
    },
    {
      label: 'Promo Phase 2 Duration Singular:# or id',
      value: `\${sku.promoDuratioNSingularPhase2:0}`,
      hint: 'Android only. SDK Version 3.2.4+. Promotional offer duration for second phase of promo, without pluralization.',
    },
  ];

  const children = smartText.map((tag) => (
    <Tooltip title={tag.hint} key={tag.value}>
      <CustomTag onClick={() => handleChange(tag.value)}>{tag.label}</CustomTag>
    </Tooltip>
  ));

  return (
    <Popover
      placement="topRight"
      trigger="click"
      content={
        <TagWrapper>
          {children}
          <Button
            type="link"
            icon={<ReadOutlined />}
            href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-smart-text"
          >
            {`Learn more about \${sku} Smart Text`}
          </Button>
        </TagWrapper>
      }
      overlayInnerStyle={{ maxWidth: '500px' }}
    >
      <PlusCircleOutlined />
    </Popover>
  );
}
