import { enableMapSet } from 'immer';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { multiClientMiddleware } from 'redux-axios-middleware';

import api from '../../src/api';
import SegmentClient from '../segment';
import AnalyticsSlice from './AnalyticsSlice';
import PaginationSlice from './PaginationSlice';
import PaywallBuilderSlice from './PaywallBuilderSlice';
import { appReducer } from './reducers/appReducer';
import { authReducer } from './reducers/authReducer';
import { campaignReducer } from './reducers/campaignReducer';
import { citationReducer } from './reducers/citationReducer';
import { crmReducer } from './reducers/crmReducer';
import { developerReducer } from './reducers/developerReducer';
import { localeReducer } from './reducers/localeReducer';
import { paywallReducer } from './reducers/paywallReducer';
import { productReducer } from './reducers/productReducer';
import { rootReducer } from './reducers/rootReducer';
import { teamReducer } from './reducers/teamReducer';
import { uiReducer } from './reducers/uiReducer';
import { userReducer } from './reducers/userReducer';

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

enableMapSet();

const SegmentMiddleware = () => (next: any) => (action: any) => {
  if (window.hasOwnProperty('analytics') && !action.type.includes('FAIL')) {
    try {
      const event = action.meta.segment.event;
      const context = action.meta.segment.context;
      SegmentClient.track(event, context);
    } catch (e) {}
  }
  next(action);
};

const middleware = applyMiddleware(
  multiClientMiddleware({
    default: { client: api.client },
    preAuth: { client: api.preAuthClient },
    bare: { client: api.bareClient },
  }),
  SegmentMiddleware
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer = combineReducers({
  auth: authReducer,
  paywallBuilder: PaywallBuilderSlice.reducer,
  pagination: PaginationSlice.reducer,
  root: rootReducer,
  app: appReducer,
  user: userReducer,
  team: teamReducer,
  paywall: paywallReducer,
  campaign: campaignReducer,
  product: productReducer,
  ui: uiReducer,
  developer: developerReducer,
  analytic: AnalyticsSlice.reducer,
  crm: crmReducer,
  citation: citationReducer,
  locale: localeReducer,
});

const store = createStore(reducer, composeEnhancers(middleware));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
