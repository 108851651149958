import React, { useEffect, useMemo, useState } from 'react';

import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import PaginationSlice from 'src/redux/PaginationSlice';

import EmptyStateSegment from '../../components/EmptyStateSegment/EmptyStateSegment';
import Loading from '../../components/Loading/Loading';
import NewAppModal from '../../components/NewAppModal/NewAppModal';
import NewKeyValueModal from '../../components/NewKeyValueModal/NewKeyValueModal';
import { useAppContext, useBooleanState } from '../../hooks';
import {
  useActions,
  useAppDispatch,
  useAppSelector,
} from '../../hooks/redux.hooks';
import { getApps, getLanguages } from '../../redux/actions/rootActions';
import { toggleNewAppModal } from '../../redux/actions/uiActions';
import { setAppId } from '../../services/LocalStorageService';
import { componentToFunction } from '../../utils/components';
import AppSwitchModal from './AppSwitchModal';
import NavigationFramework from './NavigationFramework';
import AcceptInvitePage from './accept-invite/AcceptInvitePage';
import AppSettingsPage from './app-settings/AppSettingsPage';
import CampaignsPage from './campaigns/CampaignsPage';
import PlacementsPage from './campaigns/PlacementsPage';
import CampaignDetailLegacyPage from './campaigns/detail/CampaignDetailLegacyPage';
import CampaignDetailPage from './campaigns/detail/CampaignDetailPage';
import LegacyCampaignsPage from './campaigns/legacy/LegacyCampaignsPage';
import CustomersPage from './customers';
import CustomerDetailPage from './customers/detail';
import UserEventsPage from './customers/detail/userEvents';
import UserRevenuesPage from './customers/detail/userRevenues';
import DeveloperPage from './developer';
import EmailConfirmPage from './email-confirm/EmailConfirmPage';
import EntitlementDetailPage from './entitlements/EntitlementDetailPage';
import EntitlementsPage from './entitlements/EntitlementsPage';
import InsightsPage from './insights/InsightsPage';
import IntegrationDetailPage from './integrations/IntegrationDetailPage';
import IntegrationsPage from './integrations/IntegrationsPage';
import OverviewPage from './overview/OverviewPage';
import PaywallBuilderPage from './paywalls/PaywallBuilder/PaywallBuilderPage';
import PaywallsPage from './paywalls/PaywallsPage';
import PaywallDetailLegacyPage from './paywalls/legacy/PaywallDetailLegacyPage';
import PickPlan from './pick-plan/PickPlan';
import ProductsPage from './products/ProductsPage';
import ProductDetailPage from './products/detail/ProductDetailPage';
import ProductLanguagesPage from './products/detail/ProductLanguagesPage';
import SettingsPage from './settings/SettingsPage';

type RouteType = {
  path: string | string[];
  // TODO: Figure out how to type the "component"
  // component: React.FC | ConnectedComponent<any, any>;
  component: any;
  enabled: boolean;
};

type NavigationContextType = {
  showNavigation: () => void;
  hideNavigation: () => void;
};

const REDIRECT_ROUTE_MAP = [
  ['overview.list', '/overview/'],
  ['analytics.list', '/insights/revenue/'],
  ['app.campaign.list', '/campaigns/'],
  ['app.campaign.legacy.list', '/legacy/campaigns/'],
  ['app.paywall.list', '/paywalls/'],
  ['app.productsku.list', '/products/'],
  ['app.entitlement.list', '/entitlements/'],
  ['app.user.list', '/customers/'],
  ['developer.list', '/developer/'],
  ['app.get', '/app-settings/'],
  ['org.billing.plan.update', '/pick-plan/'],
];

export const NavigationContext = React.createContext<NavigationContextType>({
  showNavigation: () => undefined,
  hideNavigation: () => undefined,
});

function AdminRouter() {
  const history = useHistory();
  const [appSwitchMenu, setAppSwitchMenu] = useState(false);
  const [isNavigationShown, showNavigation, hideNavigation] =
    useBooleanState(true);
  const location = useLocation();
  const { selectedOrg, updateSelectedOrg, userHasEntitlement, user } =
    useAppContext();
  const { setPaywallsPage } = useActions(PaginationSlice.actions);

  const dispatch = useAppDispatch();
  const { hasCurrentApp, apps, loading } = useAppSelector(({ root }) => ({
    hasCurrentApp: !!root.currentApp,
    apps: root.apps,
    loading: root.loading,
  }));

  useEffect(() => {
    dispatch(getLanguages());
  }, [dispatch]);

  if (!selectedOrg && user?.permissions) {
    if (user.default_org_member && user.default_org_member.org) {
      updateSelectedOrg(user.default_org_member.org.id);
    } else if (user.permissions[0].id) {
      //Automatically select the first org on the list
      updateSelectedOrg(user.permissions[0].id);
    }
  }

  const routes: RouteType[] = [
    {
      path: '/overview/',
      component: OverviewPage,
      enabled: userHasEntitlement('overview.list'),
    },
    {
      path: ['/app-settings/', '/app-settings/:tab/'],
      component: AppSettingsPage,
      enabled: userHasEntitlement('app.get'),
    },
    {
      path: '/settings/',
      component: SettingsPage,
      enabled: true,
    },
    {
      path: '/paywalls/',
      component: PaywallsPage,
      enabled: userHasEntitlement('app.paywall.list'),
    },
    {
      path: '/paywalls/legacy/:paywallID/',
      component: PaywallDetailLegacyPage,
      enabled: userHasEntitlement('app.paywall.get'),
    },
    {
      path: '/paywalls/:paywallID/',
      component: PaywallBuilderPage,
      enabled: userHasEntitlement('app.paywall.get'),
    },
    {
      path: '/campaigns/',
      component: CampaignsPage,
      enabled: userHasEntitlement('app.campaign.list'),
    },
    {
      path: '/legacy/campaigns/',
      component: LegacyCampaignsPage,
      enabled: userHasEntitlement('app.campaign.legacy.list'),
    },
    {
      path: '/campaigns/placements/',
      component: PlacementsPage,
      enabled: userHasEntitlement('app.campaign.list'),
    },
    {
      path: '/campaigns/rules/',
      component: CampaignsPage,
      enabled: userHasEntitlement('app.campaign.list'),
    },
    {
      path: '/campaigns/:campaignID/', //TODO
      component: CampaignDetailPage,
      enabled: userHasEntitlement('app.campaign.get'),
    },
    {
      path: '/campaigns/legacy/:campaignID/',
      component: CampaignDetailLegacyPage,
      enabled: userHasEntitlement('app.campaign.get'),
    },
    {
      path: '/products/',
      component: ProductsPage,
      enabled: userHasEntitlement('app.productsku.list'),
    },
    {
      path: '/products/:productID/',
      component: ProductDetailPage,
      enabled: userHasEntitlement('app.productsku.get'),
    },
    {
      path: '/products/:productID/languages/',
      component: ProductLanguagesPage,
      enabled: userHasEntitlement('app.locale_config.get'),
    },
    {
      path: '/integrations/',
      component: IntegrationsPage,
      enabled: userHasEntitlement('app.platform.list'),
    },
    {
      path: '/integrations/:type/',
      component: IntegrationDetailPage,
      enabled: userHasEntitlement('app.platform.get'),
    },
    {
      path: '/integrations/:type/:integrationId',
      component: IntegrationDetailPage,
      enabled: userHasEntitlement('app.platform.get'),
    },
    {
      path: '/entitlements/',
      component: EntitlementsPage,
      enabled: userHasEntitlement('app.entitlement.list'),
    },
    {
      path: '/entitlements/:entitlementID/',
      component: EntitlementDetailPage,
      enabled: userHasEntitlement('app.entitlement.get'),
    },
    {
      path: '/customers/',
      component: CustomersPage,
      enabled: userHasEntitlement('app.user.list'),
    },
    {
      path: '/customers/:userID/',
      component: CustomerDetailPage,
      enabled: true,
    },
    {
      path: '/customers/events/:name/:userID/',
      component: UserEventsPage,
      enabled: true,
    },
    {
      path: '/customers/revenue/:name/:userID/',
      component: UserRevenuesPage,
      enabled: true,
    },
    {
      path: '/developer/',
      component: DeveloperPage,
      enabled: userHasEntitlement('app.event.list'),
    },
    {
      path: '/accept-invite/',
      component: AcceptInvitePage,
      enabled: true,
    },
    {
      path: '/account/email-confirm/:token/',
      component: EmailConfirmPage,
      enabled: true,
    },
    {
      path: '/insights/:insightType/',
      component: InsightsPage,
      enabled: userHasEntitlement('analytics.list'),
    },
    {
      path: '/pick-plan/',
      component: PickPlan,
      enabled: userHasEntitlement('org.billing.plan.update'),
    },
  ];

  let children;
  if (loading) {
    children = <Loading />;
  } else if (
    selectedOrg &&
    !apps.length &&
    !location.pathname.startsWith('/overview') &&
    !location.pathname.startsWith('/settings') &&
    !location.pathname.startsWith('/account/email-confirm') &&
    !location.pathname.startsWith('/pick-plan')
  ) {
    children = (
      <div className="main-container">
        <EmptyStateSegment
          title="This organization has not created any apps."
          subtitle="Learn more about creating your first app."
          outBoundLink="https://learn.namiml.com/public-docs/nami-account/manage-your-apps#add-an-app"
        >
          <Button
            disabled={!userHasEntitlement('app.create')}
            onClick={() => dispatch(toggleNewAppModal())}
            className="add-app nami-primary-button"
          >
            <Icon name="plus" /> Add App
          </Button>
        </EmptyStateSegment>
        <AppSwitchModal
          isVisible={appSwitchMenu}
          onClose={() => setAppSwitchMenu(false)}
          onOrgClick={setDefaultOrg}
          onAddApp={openAppModal}
          onAppClick={setDefaultApp}
        />
      </div>
    );
  } else {
    children = (
      <>
        <Switch>
          {routes.map(routeMapper)}
          {renderRedirect()}
        </Switch>
        <AppSwitchModal
          isVisible={appSwitchMenu}
          onClose={() => setAppSwitchMenu(false)}
          onOrgClick={setDefaultOrg}
          onAddApp={openAppModal}
          onAppClick={setDefaultApp}
        />
      </>
    );
  }

  const navigationContext = useMemo(() => {
    return { showNavigation, hideNavigation };
  }, [showNavigation, hideNavigation]);

  return (
    <NavigationContext.Provider value={navigationContext}>
      <NavigationFramework
        isVisible={isNavigationShown}
        openAppSwitchMenu={() => setAppSwitchMenu(true)}
        onAppChange={setDefaultApp}
      />
      {children}
      <NewAppModal />
      {hasCurrentApp && <NewKeyValueModal />}
    </NavigationContext.Provider>
  );

  function setDefaultApp(appId: string): void {
    setAppId(appId);
    dispatch(getApps());
    setAppSwitchMenu(false);
    setPaywallsPage(1);
    history.push('/overview/');
  }

  function setDefaultOrg(orgId: string): void {
    updateSelectedOrg(orgId);
    setAppSwitchMenu(false);
    setPaywallsPage(1);
    history.push('/overview/');
  }

  function openAppModal(): void {
    setAppSwitchMenu(false);
    dispatch(toggleNewAppModal());
  }

  function renderRedirect(): JSX.Element | null {
    for (let i = 0; i < REDIRECT_ROUTE_MAP.length; i++) {
      const [entitlement, route] = REDIRECT_ROUTE_MAP[i];
      if (userHasEntitlement(entitlement)) {
        return <Redirect to={route} />;
      }
    }
    return null;
  }
}

export default AdminRouter;

function routeMapper(route: RouteType, index: number): JSX.Element | boolean {
  return (
    route.enabled && (
      <Route
        key={index}
        exact
        path={route.path}
        render={componentToFunction(route.component)}
      />
    )
  );
}
