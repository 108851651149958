import React from 'react';

import * as Icons from '@ant-design/icons';
import { format, toDate } from 'date-fns';
import ReactMarkdown from 'react-markdown';
import { TIconName } from 'src/components/AntIcon';
import { TSemverObj } from 'src/utils/parsing';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import {
  TSymbolComponent,
  TTextComponent,
  TTextLikeComponent,
  TTextListComponent,
} from '../../../api/types/paywallTemplate.types';
import {
  applyStyles,
  flexDirection,
  font,
  justifyContent,
  pickAndApplyBackgroundColor,
  strikethrough,
  transition,
} from '../css';
import HoverTag from './HoverTag';

type ComponentProps<T> = {
  component: T;
  inFocusedState?: boolean;
  groupId: string | null;
  minSDKVersion: TSemverObj;
  ignoreHover?: boolean; //Currently only used for bullets
};

const TextWrapper = styled.div<{
  component: TTextLikeComponent;
  inFocusedState?: boolean;
  groupId: string | null;
  minSDKVersion: TSemverObj;
}>`
  display: flex;
  ${({ component, inFocusedState, minSDKVersion }) => css`
    ${applyTextWidth(minSDKVersion)}
    ${applyTextOverflow(minSDKVersion, component)}
    ${pickAndApplyBackgroundColor(component, inFocusedState)}
    color: ${inFocusedState && component.focusedFontColor
      ? component.focusedFontColor
      : component.fontColor};
    font-size: ${component.fontSize ?? 12}px;
    text-align: ${component.alignment || 'center'};
    ${strikethrough(
      typeof component.strikethrough === 'string'
        ? false
        : component.strikethrough
    )}
    ${font(component)}
    ${applyStyles(component)}
    ${applyLinkColor(component)}
  `}
`;

const SymbolWrapper = styled.div<{
  component: TTextLikeComponent;
}>`
  display: flex;
  overflow-wrap: anywhere;
  ${transition()};
  ${({ component }) => css`
    margin-right: ${component.rightMargin || component.spacing || 0}px;
    ${pickAndApplyBackgroundColor(component)}
    color: ${component.fontColor};
    font-size: ${component.fontSize ?? 12}px;
  `}
`;

const LegalTextWrapper = styled.div<{
  component: TTextLikeComponent;
  minSDKVersion: TSemverObj;
}>`
  display: block;
  overflow-wrap: break-word;
  ${({ component, minSDKVersion }) => css`
    ${applyTextWidth(minSDKVersion)}
    ${pickAndApplyBackgroundColor(component)}
    color: ${component.fontColor};
    font-size: ${component.fontSize ?? 12}px;
    text-align: ${component.alignment || 'center'};
    ${font(component)}
    ${applyStyles(component)}
    ${applyLinkColor(component)}
  `}
`;

const BulletTextWrapper = styled.div<{
  component: TTextLikeComponent;
  inFocusedState?: boolean;
}>`
  display: inline;
  overflow-wrap: anywhere;
  width: fit-content;
  ${({ component, inFocusedState }) => css`
    ${pickAndApplyBackgroundColor(component, inFocusedState)}
    color: ${inFocusedState && component.focusedFontColor
      ? component.focusedFontColor
      : component.fontColor};
    font-size: ${component.fontSize ?? 12}px;
    text-align: ${component.alignment || 'center'};
    ${strikethrough(
      typeof component.strikethrough === 'string'
        ? false
        : component.strikethrough
    )}
    ${font(component)}
    ${applyLinkColor(component)}
  `}
`;

const TextListWrapper = styled.div<{
  component: TTextListComponent;
  inFocusedState?: boolean;
  minSDKVersion: TSemverObj;
}>`
  display: grid;
  overflow-wrap: anywhere;
  ${({ component, inFocusedState, minSDKVersion }) => css`
    ${applyTextWidth(minSDKVersion)}
    ${pickAndApplyBackgroundColor(component, inFocusedState)}
    color: ${inFocusedState && component.focusedFontColor
      ? component.focusedFontColor
      : component.fontColor};
    font-size: ${component.fontSize ?? 12}px;
    text-align: ${component.alignment || 'center'};
    ${strikethrough(
      typeof component.strikethrough === 'string'
        ? false
        : component.strikethrough
    )}
    ${font(component)}
    ${applyStyles(component)}
    ${applyLinkColor(component)}
    ${flexDirection(component)}
    ${justifyContent(component)}
    > * {
      &:not(:first-child) {
        margin-top: ${component.spacing || 0}px;
      }
    }
  `}
`;

const TextListItemWrapper = styled.div<{ component: TTextListComponent }>`
  display: flex;
  ${transition()};

  ${({ component }) => css`
    &:not(:first-child) {
      margin-top: ${component.spacing || 0}px;
    }
  `}
`;

export function Text({
  component,
  inFocusedState,
  groupId,
  minSDKVersion,
}: ComponentProps<TTextComponent>) {
  if (!component.text) return null;
  if (component.textType === 'legal') {
    return (
      <HoverTag
        title={
          component.namiComponentType ? component.title || component.id : ''
        }
        namiComponentType={component.namiComponentType}
        id={component.id}
      >
        <LegalTextWrapper component={component} minSDKVersion={minSDKVersion}>
          <ReactMarkdown allowedElements={['p', 'strong', 'em', 'a']}>
            {component.text}
          </ReactMarkdown>
        </LegalTextWrapper>
      </HoverTag>
    );
  }
  let value = component.text;
  if (component.dateTimeFormat) {
    try {
      const date = toDate(component.text);
      value = format(date, component.dateTimeFormat);
    } catch (e) {}
  }
  return (
    <HoverTag
      title={component.namiComponentType ? component.title || component.id : ''}
      namiComponentType={component.namiComponentType}
      id={component.id}
    >
      <TextWrapper
        component={component}
        inFocusedState={inFocusedState}
        groupId={groupId}
        minSDKVersion={minSDKVersion}
      >
        <ReactMarkdown allowedElements={['p', 'strong', 'em', 'a']}>
          {clearTextIfUnpopulatedCustomVariables(value)}
        </ReactMarkdown>
      </TextWrapper>
    </HoverTag>
  );

  function clearTextIfUnpopulatedCustomVariables(text: string) {
    if (text.match(/\$\{\s*launch\.customAttributes\.\w+\s*}/)) return '';
    return text;
  }
}

export function TextList({
  component,
  groupId,
  minSDKVersion,
}: ComponentProps<TTextListComponent>) {
  const { fontColor, fontSize, bulletComponent, texts, spacing } = component;
  const bullet = bulletComponent
    ? { fontColor, fontSize, spacing, ...bulletComponent }
    : null;
  if (!Array.isArray(texts)) return null;
  const listItems = texts.map((text, i) =>
    !text ? null : (
      <TextListItemWrapper key={i} component={component}>
        {bullet && (
          <Symbol
            component={bullet}
            groupId={groupId}
            minSDKVersion={minSDKVersion}
            ignoreHover={true}
          />
        )}
        <BulletTextWrapper component={component}>
          <ReactMarkdown>{text}</ReactMarkdown>
        </BulletTextWrapper>
      </TextListItemWrapper>
    )
  );
  return (
    <HoverTag
      title={component.namiComponentType ? component.title || component.id : ''}
      namiComponentType={component.namiComponentType}
      id={component.id}
    >
      <TextListWrapper component={component} minSDKVersion={minSDKVersion}>
        {listItems}
      </TextListWrapper>
    </HoverTag>
  );
}

export function Symbol({
  component,
  ignoreHover,
}: ComponentProps<TSymbolComponent>) {
  const Icon = Icons[component.name as TIconName]
    ? (Icons[component.name as TIconName] as React.FC)
    : undefined;
  if (typeof Icon === 'undefined') return null;
  return (
    <HoverTag
      title={
        !ignoreHover && component.namiComponentType
          ? component.title || component.id
          : ''
      }
      namiComponentType={component.namiComponentType}
      id={!ignoreHover ? component.id : undefined}
    >
      <SymbolWrapper component={component}>
        <Icon />
      </SymbolWrapper>
    </HoverTag>
  );
}

function applyLinkColor(
  component: TTextLikeComponent
): FlattenSimpleInterpolation {
  const linkColor = component.linkColor || 'blue';
  return css`
    a:link {
      color: ${linkColor};
      text-decoration-line: underline;
    }
    a:visited {
      color: ${linkColor};
      text-decoration-line: underline;
    }
    a:hover {
      color: ${linkColor};
    }
    a:active {
      color: ${linkColor};
      text-decoration-line: underline;
    }
  `;
}

function applyTextWidth(minSDKVersion: TSemverObj): FlattenSimpleInterpolation {
  if (minSDKVersion.minor >= 2) {
    //3.2 logic
    return css`
      width: fit-content;
    `;
  }
  return css`
    width: 100%;
  `;
}

function applyTextOverflow(
  minSDKVersion: TSemverObj,
  component: TTextLikeComponent
): FlattenSimpleInterpolation {
  if (minSDKVersion.minor >= 2) {
    //Custom logic to solve NC-4110
    if (
      component.namiComponentType === 'productBadge' &&
      component.width === 'fitContent'
    ) {
      return css`
        white-space: nowrap;
      `;
    }
  }
  return css`
    overflow-wrap: anywhere;
  `;
}
