import React, { useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { TCollapseContainer } from 'src/api/types/paywallTemplate.types';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import { interpolate } from 'src/utils/interpolation';
import { TSemverObj } from 'src/utils/parsing';
import styled, { css } from 'styled-components';

import { applyStyles, transition } from '../css';
import TemplateComponent from '../TemplateComponent';
import { ContainerWrapper } from './Container';

type CollapseContainerProps = {
  component: TCollapseContainer;
  minSDKVersion: TSemverObj;
};

const Wrapper = styled(ContainerWrapper)`
  ${transition()}
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ component }) => {
    return css`
      ${applyStyles(component)}
    `;
  }}
`;

const selector = createSelector(
  [({ paywallBuilder: { openHeaderIds } }: RootState) => openHeaderIds],
  (openHeaderIds) => ({
    openHeaderIds: openHeaderIds || [],
  })
);

export default function CollapseContainer({
  component: { collapseHeader, ...component },
  minSDKVersion,
}: CollapseContainerProps) {
  const { openHeaderIds } = useAppSelector(selector);
  const open = openHeaderIds.has(component.id!);

  const parsedComponents = useMemo(() => {
    const header = !!collapseHeader ? (
      <TemplateComponent
        key={`collapse-header-closed`}
        component={collapseHeader}
        groupId={null}
        minSDKVersion={minSDKVersion}
      />
    ) : null;
    if (!open) return [header];

    return [
      header,
      interpolate(component.components, {}).map((child, i) => (
        <TemplateComponent
          key={`collapse-${i}`}
          component={child}
          groupId={null}
          minSDKVersion={minSDKVersion}
        />
      )),
    ];
  }, [open, component.components, collapseHeader, minSDKVersion]);

  return (
    <Wrapper component={{ ...component }} minSDKVersion={minSDKVersion}>
      {parsedComponents}
    </Wrapper>
  );
}
