import React, { useEffect, useState } from 'react';

import { Form, Input, Space } from 'antd';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';

import { FieldObject } from '../../../utils/formFieldBuilding';
import InputLabel from './InputLabel';
import VariableTagCloud from './VariableTagCloud';

type TextInputProps = Omit<FieldObject, 'variable'> & {
  defaultValue?: string | number;
  onChange: (value: any) => void;
  label: string;
  controlled?: boolean;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
};

const TextInputItem = styled(Form.Item)`
  .ant-form-item-label label {
    width: 100%;
    justify-content: space-between;
  }
  margin-bottom: 12px;
`;

export default function TextAreaInput({
  defaultValue,
  onChange,
  controlled = false,
  ...field
}: TextInputProps) {
  const placeholder = field.placeholder || field.name;
  const [inputValue, setInputValue] = useState<string | Array<string>>(
    field.value
  );
  const [debouncedValue] = useDebounce(inputValue, 150);

  useEffect(() => {
    handleInputChange(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const label = (
    <Space style={{ width: '100%' }}>
      <InputLabel {...field} />
      {field.showVariableCloud && (
        <VariableTagCloud handleChange={appendTagToInput} />
      )}
    </Space>
  );

  const itemProps = {
    name: controlled ? undefined : field.variable,
    label: label,
  };

  const value = controlled ? field.value : undefined;

  return (
    <>
      <TextInputItem {...itemProps}>
        <Input.TextArea
          maxLength={field.maxLimit || undefined}
          minLength={field.minLimit || undefined}
          disabled={!field.editable}
          rows={5}
          placeholder={placeholder}
          value={
            Array.isArray(inputValue)
              ? inputValue.join('\n')
              : (inputValue as string)
          }
          onChange={(e) => setInputValue(e.target.value as string)}
          status={checkForError(value) ? 'error' : undefined}
        />
      </TextInputItem>
    </>
  );

  function checkForError(value: string): boolean {
    return checkForInvalidMarkdown(value) || checkForWhiteSpace(value);
  }

  function checkForWhiteSpace(value: string): boolean {
    if (Array.isArray(value)) value = value.join('\n');
    return value.trimEnd() !== value;
  }

  //Throw error if header, numbered list, or unordered list used
  function checkForInvalidMarkdown(value: string): boolean {
    if (Array.isArray(value)) value = value.join('\n');
    const disallowedMarkdownCheck = /(-|#|^\*|([0-9]\.))\s\w/;
    return !!value.match(disallowedMarkdownCheck);
  }

  function appendTagToInput(tagValue: string): void {
    setInputValue(`${value}${tagValue}`);
  }

  function handleInputChange(value: string | Array<string>): void {
    if (!field.editable || !isWithinMinMax(field, value.length)) return;
    onChange(
      shouldSplitLines(field) && !Array.isArray(value)
        ? value.split('\n')
        : value
    );
  }

  function isWithinMinMax(
    field: Omit<FieldObject, 'variable'>,
    value: number
  ): boolean {
    return (
      (!field.maxLimit || field.maxLimit >= value) &&
      (!field.minLimit || field.minLimit <= value)
    );
  }

  function shouldSplitLines(field: Omit<FieldObject, 'variable'>): boolean {
    return field.splitLines || field.type === 'splitTextArea';
  }
}
