import React from 'react';

import { TSemverObj } from 'src/utils/parsing';
import styled from 'styled-components';

import { TProductContainer } from '../../../api/types/paywallTemplate.types';
import { useAppSelector } from '../../../hooks/redux.hooks';
import { FeaturedContext } from '../contexts';
import { transition } from '../css';
import TemplateComponent from '../TemplateComponent';
import { getSkuButtonComponents } from '../utils';
import { ContainerWrapper } from './Container';
import HoverTag from './HoverTag';

type ComponentProps<T> = {
  component: T;
  inFocusedState?: boolean;
  groupId: string | null;
  minSDKVersion: TSemverObj;
};

const Wrapper = styled(ContainerWrapper)`
  ${transition()}
  display: flex;
  justify-content: center;
`;

export default function ProductContainer({
  component,
  inFocusedState,
  groupId,
  minSDKVersion,
}: ComponentProps<TProductContainer>): JSX.Element {
  const { items, mediaList } = useAppSelector(({ paywallBuilder }) => {
    const { skuItems, platformId, groupId, mediaList } = paywallBuilder;
    let items;
    if (component.products === 'subset' && component.subsetGroup) {
      items = Object.values(
        (component.subsetGroup &&
          platformId &&
          skuItems[component.subsetGroup] &&
          skuItems[component.subsetGroup][platformId]) ||
          {}
      );
    } else {
      items = Object.values(
        (groupId && platformId && skuItems[groupId][platformId]) || {}
      );
    }
    return { items, mediaList };
  });
  const offerState = useAppSelector(({ paywallBuilder }) => {
    const { anySkuHasTrialOffer, anySkuHasIntroOffer, anySkuHasPromoOffer } =
      paywallBuilder;

    return {
      freeTrialEligible: anySkuHasTrialOffer,
      introEligible: anySkuHasIntroOffer,
      promoEligible: anySkuHasPromoOffer,
    };
  });
  const components = getSkuButtonComponents(
    items,
    component,
    mediaList,
    offerState
  );
  return (
    <HoverTag
      title={component.namiComponentType ? 'Repeating Product Group' : ''}
      namiComponentType={component.namiComponentType}
      id={component.id}
    >
      <Wrapper
        component={component}
        inFocusedState={inFocusedState}
        minSDKVersion={minSDKVersion}
      >
        {components.map(([featured, children], i) => (
          <FeaturedContext.Provider key={i} value={featured}>
            {children.map((child, j) => (
              <TemplateComponent
                key={j}
                component={child}
                inFocusedState={inFocusedState}
                groupId={groupId}
                minSDKVersion={minSDKVersion}
              />
            ))}
          </FeaturedContext.Provider>
        ))}
      </Wrapper>
    </HoverTag>
  );
}
