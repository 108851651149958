import React, { useEffect, useState } from 'react';

import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  MailOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Typography,
  UploadFile,
  notification,
} from 'antd';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadRequestOption } from 'node_modules/rc-upload/lib/interface';
import api from 'src/api';
import { UserType } from 'src/api/types/main.types';
import ImageCroppingModal from 'src/components/ImageCroppingModal';
import SectionTitle from 'src/components/SectionTitle';
import { useAppContext, useBooleanState } from 'src/hooks';
import Token from 'src/services/token';

import { WhiteText, buildFile } from '../../app-settings/general/GeneralTab';
import { StyledUpload } from '../../paywalls/PaywallBuilder/editor/inputs/ImageButton';

export default function ProfilePage() {
  const context = useAppContext();
  const [form] = Form.useForm<UserType>();
  const [isCropperOpen, showCropper, hideCropper] = useBooleanState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<UploadFile | null>(
    context.user?.avatar ? buildFile(context.user.avatar) : null
  );
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const defaultOrgId =
    context.user?.default_org_member && context.user.default_org_member.org
      ? context.user?.default_org_member?.org.id
      : undefined;

  useEffect(() => {
    setImageUrl(context.user?.avatar || null);
  }, [context]);
  const [emailHasChanged, setEmailHasChanged] = useState(false);
  const [optedIntoMarketing, setOptedIntoMarketing] = useState(
    context.user?.opted_in_marketing || false
  );

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      notification.error({ message: 'Avatar must be smaller than 1MB' });
    }
    return isLt1M;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  if (!context.user) return null;

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        ...context.user,
        default_org_member: defaultOrgId,
      }}
      onFinish={() => formSubmit()}
      onValuesChange={(changedValues) => {
        if (changedValues.hasOwnProperty('username')) setEmailHasChanged(true);
      }}
      requiredMark={false}
    >
      <SectionTitle>My Profile</SectionTitle>
      <Row gutter={16}>
        <Col md={12} xs={24}>
          <Form.Item label="Avatar">
            <StyledUpload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              accept=".jpg,.jpeg,.png"
              showUploadList={{
                previewIcon: <WhiteText as={EditOutlined} />,
                removeIcon: <WhiteText as={DeleteOutlined} />,
              }}
              customRequest={({ file }: UploadRequestOption) =>
                handleNewFile(file)
              }
              beforeUpload={beforeUpload}
              fileList={file ? [file] : []}
              onPreview={showCropper}
              onChange={handleChange}
              maxCount={1}
              disabled={loading}
            >
              {!imageUrl && !loading && uploadButton}
            </StyledUpload>
            {!!imageUrl && (
              <ImageCroppingModal
                imageUrl={imageUrl}
                visible={isCropperOpen && !loading}
                onCancel={hideCropper}
                onCrop={handleNewFile}
                aspectRatio={1}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[
              {
                required: true,
                message: 'Please provide a first name.',
              },
            ]}
          >
            <Input placeholder="First Name" prefix={<UserOutlined />} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[
              {
                required: true,
                message: 'Please provide a last name.',
              },
            ]}
          >
            <Input placeholder="Last Name" prefix={<UserOutlined />} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name="default_org_member"
            label="Default Org"
            tooltip={'You will be logged into this organization by default.'}
          >
            <Select
              options={context.user.orgs.map((org) => {
                return {
                  label: org.name,
                  value: org.id,
                };
              })}
              placeholder="Select an org"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item
            name="username"
            label="Email Address"
            rules={[
              {
                required: true,
                message: 'Please provide an email address.',
              },
              {
                type: 'email',
                message: 'Please enter a valid email',
              },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              disabled={true}
              placeholder="Email"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item name="opted_in_marketing">
            <Space direction="horizontal">
              <Switch
                size="small"
                defaultChecked={context.user.opted_in_marketing}
                onChange={(checked) => setOptedIntoMarketing(checked)}
              />
              <Typography.Text>
                I'd like to receive emails about product updates and company
                news.
              </Typography.Text>
            </Space>
          </Form.Item>
        </Col>
      </Row>
      <Button htmlType="submit" type="primary">
        Update Profile
      </Button>
    </Form>
  );

  function formSubmit() {
    const payload: UserType = {
      ...form.getFieldsValue(),
      avatar: imageUrl,
      opted_in_marketing: optedIntoMarketing,
    };
    api.updateUser(payload).then((user) => {
      notification.success({ message: 'Successfully updated profile.' });
      if (emailHasChanged) {
        notification.success({
          message: 'To confirm an email address change, check your inbox.',
        });
      }
      context.setUser(user);
      window.analytics.track('Account Profile Updated', user);
    });
  }

  function handleChange(info: UploadChangeParam): void {
    if (info.file.status === 'removed') {
      deleteImage();
    } else if (info.file.status === 'uploading') {
      setLoading(true);
      // return;
    } else if (info.file.status === 'done') {
      setLoading(false);
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setFile(info.file);
      });
    }
  }

  function deleteImage() {
    setImageUrl(null);
    setFile(null);
  }

  function handleNewFile(file: string | Blob | RcFile): void {
    if (file && typeof file !== 'string') {
      setLoading(true);
      api.uploadFile(file, { orgId: Token.getOrgId()! }).then((url) => {
        setImageUrl(url);
        setFile(buildFile(url));
        setLoading(false);
      });
    } else {
      setFile(buildFile(file));
    }
  }
}
