import React, { useMemo } from 'react';

import {
  CloseOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  ConfigProvider,
  Empty,
  Image,
  Popconfirm,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { TEntitlementProduct } from 'src/api/types/entitlement.types';
import { TProduct } from 'src/api/types/sku.types';
import { platformLogo } from 'src/services/helpers';
import { namiDarkOrange, namiLightOrange } from 'src/variables';
import styled from 'styled-components';

import { useAppContext, useBooleanState } from '../../../hooks';
import {
  useEntitlementQuery,
  useUpdateProductAssociationMutation,
} from '../../../hooks/queries/entitlement.hooks';
import { useProductsQuery } from '../../../hooks/queries/product.hooks';
import EntitlementProductAssociationModal from './EntitlementProductAssociationModal';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function EntitlementProductTable() {
  const history = useHistory();
  const { userHasEntitlement } = useAppContext();
  const productsQuery = useProductsQuery({ pageSize: 2000, archived: false });
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const entitlementId = useParams<{ entitlementID: string }>().entitlementID;
  const entitlementQuery = useEntitlementQuery(entitlementId);
  const updateProductsMutation = useUpdateProductAssociationMutation(
    entitlementId,
    { onSuccess: closeModal }
  );

  const productIdMap = useMemo(() => {
    return (productsQuery.data?.results || []).reduce((output, product) => {
      return { ...output, [product.id]: product };
    }, {} as Record<string, TProduct>);
  }, [productsQuery.data?.results]);

  const availableProducts = useMemo(() => {
    const associatedIds = new Set(
      entitlementQuery.data?.skus.map(({ id }) => id) || []
    );
    return (productsQuery.data?.results || []).filter(
      (product) => !associatedIds.has(product.id)
    );
  }, [entitlementQuery.data?.skus, productsQuery.data?.results]);

  return (
    <>
      <Header>
        <div>
          <Typography.Title level={5} style={{ marginTop: '48px' }}>
            Associated Products
          </Typography.Title>
          <Typography.Paragraph>
            Associated products grant this entitlement when they are purchased
            on-device.
          </Typography.Paragraph>
        </div>
        <Button
          icon={<PlusOutlined />}
          disabled={
            availableProducts.length === 0 ||
            !userHasEntitlement('app.entitlement.update')
          }
          onClick={openModal}
          loading={productsQuery.isFetching}
        >
          Associate Product SKUs
        </Button>
      </Header>
      <ConfigProvider
        renderEmpty={() => (
          <Empty imageStyle={{ display: 'none' }} description={<></>}>
            <Tag
              color="warning"
              icon={<ExclamationCircleFilled />}
              style={{
                color: namiDarkOrange,
                borderColor: namiLightOrange,
                marginBottom: '16px',
              }}
            >
              0 Associated Products
            </Tag>
          </Empty>
        )}
      >
        <Table
          dataSource={entitlementQuery.data?.skus || []}
          pagination={false}
          loading={entitlementQuery.isFetching}
          bordered={true}
          columns={[
            { title: 'Name', dataIndex: 'name', key: 'name' },
            {
              title: 'SKU ID',
              key: 'sku_ref_id',
              responsive: ['lg'],
              render: (_, product) => {
                if (!(product.id in productIdMap)) return <Spin />;
                return productIdMap[product.id].sku_ref_id;
              },
            },
            {
              title: 'Platform',
              key: 'platform',
              responsive: ['lg'],
              render: (_, product) => {
                if (!(product.id in productIdMap)) return <Spin />;
                const { platform_name, platform_type } =
                  productIdMap[product.id];
                return (
                  <Space>
                    <Image
                      src={platformLogo(platform_type)}
                      height={18}
                      width={18}
                      preview={false}
                    />
                    {platform_name}
                  </Space>
                );
              },
            },
            {
              title: 'Associated Date',
              key: 'associated_date',
              render: ({ associated_date }: TEntitlementProduct) => {
                return (
                  <Tooltip
                    title={moment(associated_date).format('YYYY-MM-DD h:mm A')}
                  >
                    <span>{moment(associated_date).fromNow()}</span>
                  </Tooltip>
                );
              },
              responsive: ['lg'],
            },
            {
              title: 'Actions',
              key: 'action',
              width: 'fit-content',
              render: (_, product) => (
                <Space direction="horizontal">
                  <Button
                    type="link"
                    icon={<EyeOutlined />}
                    onClick={() => history.push(`/products/${product.id}/`)}
                  >
                    View
                  </Button>
                  <Popconfirm
                    title="Are you sure? If you detach, users who buy this product won't be granted this permission."
                    cancelText="No"
                    okText="Yes, detach"
                    onConfirm={() => {
                      const data = { id: product.id, associated: false };
                      updateProductsMutation.mutate([data]);
                    }}
                  >
                    <Button
                      type="link"
                      icon={<CloseOutlined />}
                      disabled={!userHasEntitlement('app.entitlement.update')}
                    >
                      Detach
                    </Button>
                  </Popconfirm>
                </Space>
              ),
            },
          ]}
        />
      </ConfigProvider>
      <EntitlementProductAssociationModal
        isOpen={isModalOpen}
        isLoading={updateProductsMutation.isLoading}
        products={availableProducts}
        onOk={updateProductsMutation.mutate}
        onCancel={closeModal}
      />
    </>
  );
}
