import React, { useMemo } from 'react';

import { TSemverObj } from 'src/utils/parsing';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import {
  TCarouselContainer,
  TCarouselSlide,
  TCarouselSlidesState,
  TCollapseContainer,
  TContainer,
  TProductContainer,
  TRepeatingList,
} from '../../../api/types/paywallTemplate.types';
import { useAppSelector } from '../../../hooks/redux.hooks';
import { interpolate } from '../../../utils/interpolation';
import { transition } from '../css';
import TemplateComponent from '../TemplateComponent';
import CarouselIndicator from './CarouselIndicator';
import { ContainerWrapper } from './Container';
import HoverTag from './HoverTag';

type CarouselContainerProps = {
  component: TCarouselContainer;
  slides?: TCarouselSlidesState;
  minSDKVersion: TSemverObj;
};

const Wrapper = styled(ContainerWrapper)`
  ${transition()}
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ component }) => {
    return css`
      ${applyPadding(component)}
    `;
  }}
`;

function applyPadding(
  component: Omit<
    | TCarouselContainer
    | TContainer
    | TProductContainer
    | TCollapseContainer
    | TRepeatingList,
    'name'
  >
): FlattenSimpleInterpolation {
  const leftPadding: number = component.previousSlidePadding || 0;
  const rightPadding: number = component?.nextSlidePadding || 0;
  return css`
    ${component?.previousSlidePadding !== undefined
      ? `padding-left: ${leftPadding}px;`
      : ``}
    ${component?.nextSlidePadding !== undefined
      ? `padding-right: ${rightPadding}px;`
      : ``}
  `;
}

const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 1rem;
`;

const Dot = styled.span`
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
`;

export default function CarouselContainer({
  component: {
    loopVariable,
    loopSource,
    'ui.name': carouselName,
    ...component
  },
  slides: parsedSlides,
  minSDKVersion,
}: CarouselContainerProps) {
  const slides = useAppSelector((state) => state.paywallBuilder.slides);

  const slideId = slides[carouselName];
  const isValid =
    loopSource &&
    (typeof loopSource === 'string' || Array.isArray(loopSource)) &&
    typeof loopVariable === 'string';

  const slidesArray: TCarouselSlide[] = useMemo(() => {
    if (!isValid) return [];
    if (Array.isArray(loopSource)) return loopSource;
    return parsedSlides ? parsedSlides[loopSource][carouselName] : [];
  }, [isValid, carouselName, parsedSlides, loopSource]);

  const slide = useMemo(() => {
    if (!isValid) return null;
    return (
      (slideId && slidesArray.find(({ id }) => slideId === id)) ||
      slidesArray[0] ||
      null
    );
  }, [isValid, slidesArray, slideId]);

  const parsedComponents = useMemo(() => {
    if (!isValid || slide === null) return [];
    const replacements = { [loopVariable]: slide };
    return interpolate(component.components, replacements).map((child, i) => (
      <TemplateComponent
        key={`${carouselName}-${i}`}
        component={child}
        groupId={null}
        minSDKVersion={minSDKVersion}
      />
    ));
  }, [
    loopVariable,
    component.components,
    isValid,
    slide,
    carouselName,
    minSDKVersion,
  ]);

  if (!isValid) return null;
  const showDots = component.showIndicators === false ? false : true;
  const dots = slidesArray.map(({ id }) => {
    if (slide?.id === id && component.activeIndicator)
      return (
        <CarouselIndicator key={id} component={component.activeIndicator} />
      );
    if (component.indicator)
      return <CarouselIndicator key={id} component={component.indicator} />;
    const color =
      slide?.id === id
        ? component.activeIndicatorColor
        : component.indicatorColor;
    return <Dot key={id} color={color} />;
  });
  return (
    <HoverTag
      title={component.namiComponentType ? 'Carousel' : ''}
      namiComponentType={component.namiComponentType}
      id={component.id}
    >
      <Wrapper
        component={{ ...component, direction: 'vertical' }}
        minSDKVersion={minSDKVersion}
      >
        {parsedComponents}
        {showDots && <DotWrapper>{dots}</DotWrapper>}
      </Wrapper>
    </HoverTag>
  );
}
