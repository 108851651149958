import React from 'react';

import { TDeviceOrientation } from 'src/api/types/paywall.types';
import styled from 'styled-components';

import { colorBlack, colorNegative } from '../../variables';

type IPadProps = {
  orientation: TDeviceOrientation;
  children: JSX.Element | JSX.Element[];
  inFocusedState: boolean; //Unused
  scale?: number;
};

const LandscapeWrapper = styled.div<{
  height: number;
  scale?: number;
}>`
  --height: ${({ height }) => height}px;
  width: calc(var(--height) * 1.435654);
  height: var(--height);
  background-color: ${colorBlack};
  margin: 0 auto;
  border-radius: 55px;
  padding: 40px;
  z-index: 1;
  box-shadow: 0 35px 60px -24px rgba(0 0 0 / 0.4);
  user-select: none;
  ${({ scale }) => (scale ? `transform: scale(${scale});` : '')}
`;

const PortraitWrapper = styled.div<{
  height: number;
  scale?: number;
}>`
  --height: ${({ height }) => height}px;
  width: calc(var(--height) * 0.696546661);
  height: var(--height);
  background-color: ${colorBlack};
  border-radius: 55px;
  margin: 0 auto;
  padding: 40px;
  z-index: 2;
  box-shadow: 0 35px 60px -24px rgba(0 0 0 / 0.4);
  user-select: none;
  ${({ scale }) => (scale ? `transform: scale(${scale});` : '')}

  transform-origin: center center;
`;

const Body = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colorBlack};
  position: relative;
  z-index: 2;
`;

const TabletView = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colorNegative};
  border-radius: 12px;
  position: relative;
  overflow: hidden;
`;

const TabletInner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: initial;
`;

export default function IPad({ children, scale, orientation }: IPadProps) {
  const height = orientation === 'landscape' ? 820 : 1106;
  const Wrapper =
    orientation === 'landscape' ? LandscapeWrapper : PortraitWrapper;
  return (
    <Wrapper height={height} scale={scale}>
      <Body className="namipreviewer ipad">
        <TabletView>
          <TabletInner>{children}</TabletInner>
        </TabletView>
      </Body>
    </Wrapper>
  );
}
