import React from 'react';

import { TDevice } from 'src/api/types/paywall.types';
import { TSemverObj } from 'src/utils/parsing';
import styled, { css } from 'styled-components';

import { TContainer } from '../../../api/types/paywallTemplate.types';
import {
  alignAndJustifyItems,
  paddingAndMargin,
  pickAndApplyBackgroundColor,
  transition,
} from '../css';
import TemplateComponent from '../TemplateComponent';
import { withOverrides } from '../utils';

type BackgroundContainerProps = {
  component: TContainer;
  device: TDevice;
  zIndex: number;
  fullscreen?: boolean;
  groupId: string | null;
  minSDKVersion: TSemverObj;
};

const Wrapper = styled.div<
  Omit<BackgroundContainerProps, 'device'> & { $device: TDevice }
>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  width: 100%;
  bottom: -1px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  ${({ component, $device, zIndex, fullscreen }) => css`
    z-index: ${zIndex};
    border-radius: ${$device === 'phone' ? (fullscreen ? 0 : 30) : 0}px;
    top: ${$device === 'phone' ? (fullscreen ? 0 : 40) : 0}px;
    ${pickAndApplyBackgroundColor(component)}
    ${transition()}
    ${paddingAndMargin(component)}
    ${alignAndJustifyItems(component)}
  `}
`;

export default function BackgroundContainer({
  component,
  device,
  zIndex,
  fullscreen,
  groupId,
  minSDKVersion,
}: BackgroundContainerProps) {
  return (
    <Wrapper
      component={withOverrides(component)}
      $device={device}
      zIndex={zIndex}
      fullscreen={fullscreen}
      groupId={groupId}
      minSDKVersion={minSDKVersion}
    >
      {(component.components || []).map((child, i) => (
        <TemplateComponent
          key={i}
          component={child}
          groupId={groupId}
          minSDKVersion={minSDKVersion}
        />
      ))}
    </Wrapper>
  );
}
