import { SelectableItemType, TPaywallMedia } from 'src/api/types/paywall.types';
import { buildMediaVariables } from 'src/pages/admin/paywalls/utils/variables';

import {
  TBaseComponent,
  TButtonContainer,
  TComponent,
  TConditionalComponent,
  TProductContainer,
  TTestObject,
} from '../../api/types/paywallTemplate.types';
import { interpolate } from '../../utils/interpolation';

const mocks = {
  price: '$XX.XX',
  pricePerMonth: '$X.XX',
  pricePerDay: '$X.XX',
  pricePerWeek: '$X.XX',
  pricePerWeekRounded: '$X',
  pricePerMonthRounded: '$X',

  period: 'XXXXX',
  periodNumber: 'X',
  periodInMonths: 'XXXXX',
  periodNumberInMonths: 'X',

  duration: 'XXXXX',
  durationSingular: 'XXXXX',
  durationInMonths: 'XXXXX',
  durationInMonthsSingular: 'XXXXX',

  freeTrialPeriodNumber: 'X',
  freeTrialPeriod: 'XXXXX',
  freeTrialDuration: 'XXXXX',
  freeTrialDurationSingular: 'XXXXX',

  introductoryPrice: '$XX.XX',
  introductoryPricePerMonth: '$X.XX',
  introductoryPeriod: 'XXXXX',
  introductoryPeriodNumber: 'X',
  introductoryDuration: 'XXXXX',
  introductoryDurationSingular: 'XXXXX',

  'percentagePriceDifferenceFromSku:1': 'XX%',
  'percentagePriceDifferenceFromSku:2': 'XX%',
  'percentagePriceDifferenceFromSku:3': 'XX%',
  'percentagePriceDifferenceFromSku:4': 'XX%',

  'priceDifferenceFromSku:1': '$XX.XX',
  'priceDifferenceFromSku:2': '$XX.XX',
  'priceDifferenceFromSku:3': '$XX.XX',
  'priceDifferenceFromSku:4': '$XX.XX',

  promoPrice: '$XX.XX',
  'promoPrice:0': '$XX.XX',
  'promoPrice:1': '$XX.XX',
  promoPeriod: 'XXXXX',
  'promoPeriod:0': 'XXXXX',
  'promoPeriod:1': 'XXXXX',
  promoPeriodNumber: 'X',
  'promoPeriodNumber:0': 'X',
  'promoPeriodNumber:1': 'X',
  promoDuration: 'XXXXX',
  'promoDuration:0': 'XXXXX',
  'promoDuration:1': 'XXXXX',
  promoDurationSingular: 'XXXXX',
  'promoDurationSingular:0': 'XXXXX',
  'promoDurationSingular:1': 'XXXXX',
  promoPricePhase2: '$XX.XX',
  'promoPricePhase2:0': '$XX.XX',
  'promoPricePhase2:1': '$XX.XX',
  promoPricePerMonthPhase2: '$X.XX',
  'promoPricePerMonthPhase2:0': '$X.XX',
  'promoPricePerMonthPhase2:1': '$X.XX',
  promoDurationPhase2: 'XXXXX',
  'promoDurationPhase2:0': 'XXXXX',
  'promoDurationPhase2:1': 'XXXXX',
  promoDurationSingularPhase2: 'XXXXX',
  'promoDurationSingularPhase2:0': 'XXXXX',
  'promoDurationSingularPhase2:1': 'XXXXX',
  promoPeriodPhase2: 'XXXXX',
  'promoPeriodPhase2:0': 'XXXXX',
  'promoPeriodPhase2:1': 'XXXXX',
  promoPeriodNumberPhase2: 'X',
  'promoPeriodNumberPhase2:0': 'X',
  'promoPeriodNumberPhase2:1': 'X',
};

export function getInterpolatedButtonComponents(
  component: TButtonContainer
): TComponent[] {
  const replacements = {
    sku: mocks,
  };
  const base = component.components || [];
  return interpolate(base, interpolate(replacements, replacements));
}

export function getSkuButtonComponents(
  items: SelectableItemType[],
  component: TProductContainer,
  mediaList: { [mediaName: string]: Pick<TPaywallMedia, 'content'> } = {},
  additionalVariableStates?: {}
): [boolean, TComponent[]][] {
  const base = component.productBaseComponents || component.components || [];
  const featured = component.productFeaturedComponents || base;
  return items.map((item) => {
    const components = item.featured ? featured : base;
    const entitlementMap = item.entitlements.map(
      (entitlement) => entitlement.entitlement_ref_id
    );
    const variables = {
      ...mocks,
      ...item.variables,
      ...additionalVariableStates,
      id: item.sku_id,
      entitlements: entitlementMap,
    };
    const replacements = {
      sku: variables,
      media: buildMediaVariables(mediaList, { convertToUrl: true }),
    };

    return [
      item.featured,
      interpolate(components, interpolate(replacements, replacements)),
    ];
  }, []);
}

export function testObjectMatches({
  value,
  expected,
  operator,
}: TTestObject): boolean {
  const variableRegex = /\${[A-Za-z.]+}/g;
  if (operator === 'equals') return value === expected;
  if (operator === 'notEquals') return value !== expected;
  if (operator === 'contains') {
    if (
      !Array.isArray(value) &&
      typeof value !== 'string' &&
      !(value instanceof String)
    ) {
      value = value.toString();
    }
    return value.includes(expected);
  }
  if (operator === 'notContains') {
    if (
      !Array.isArray(value) &&
      typeof value !== 'string' &&
      !(value instanceof String)
    ) {
      value = value.toString();
    }
    return !value.includes(expected);
  }
  if (operator === 'set')
    return !!value && !value.toString().match(variableRegex);
  if (operator === 'notSet')
    return !value || value.toString().match(variableRegex);
  return false;
}

export function conditionComponentMatches(
  condition: TConditionalComponent
): boolean {
  if (condition.assertions) {
    return condition.assertions.every(testObjectMatches);
  } else if (condition.orAssertions) {
    return (
      !condition.orAssertions.length ||
      condition.orAssertions.some(testObjectMatches)
    );
  }
  return false;
}

export function withOverrides<T extends TBaseComponent>({
  conditionAttributes,
  ...component
}: T): T {
  if (!conditionAttributes) return component as T;
  return conditionAttributes.reduce((component, condition): T => {
    if (!conditionComponentMatches(condition)) return component;
    return { ...component, ...condition.attributes };
  }, component as T);
}
