import { RcFile } from 'antd/es/upload';

import { TIconName } from '../../components/AntIcon';
import {
  CampaignRuleReferenceType,
  LegacyCampaignReferenceType,
} from './main.types';
import { TPaywallTemplate } from './paywallTemplate.types';
import { TProductEntitlement } from './sku.types';

type LooseObject = { [key: string]: any };

export type TPaywallMarketingContent = {
  body?: string;
  title?: string;
};

export type TComponentPaywallPayload = {
  app: string;
  type: string;
  name: string;
  description: string;
  archived?: boolean;
};

export type TPaywallPayload = TComponentPaywallPayload & {
  allow_closing: boolean;
  apple_offer_code: string;
  background_image_url_phone: string | null;
  background_image_url_tablet: string | null;
  developer_paywall_id?: string | null;
  language: string;
  template: TPaywallTemplate;
  marketing_content: TPaywallMarketingContent;
  purchase_terms: string;
  restore_control: boolean;
  scrollable_region_size: string;
  show_nami_purchase_success_message: boolean;
  sign_in_control: boolean;
  skus_in_scrollable_region: boolean;
  style?: string | null;
  use_bottom_overlay: boolean;
};

export type PaywallType = TPaywallPayload & {
  id: string;
  style: string | null;
  developer_paywall_id: string | null;
  language_humanized: string;
  config_warnings: object;
  delete_warnings: object;
  legal_citation: string;
  media: { [key: string]: string };
  campaigns: LegacyCampaignReferenceType[];
  rules: CampaignRuleReferenceType[];
};

export type PaywallFormType = PaywallType & {
  form_factor: TDevice;
  unlocked: boolean;
  form_factor_orientation: TFormFactorOrientation;
};

export type SelectableItemType = {
  id: string;
  sku_id: string;
  featured: boolean;
  selected: boolean;
  name: string;
  sku_ref_id: string;
  sku_type: string;
  variables: { [key: string]: any };
  entitlements: TProductEntitlement[];
};

export type TMenuItem = {
  id: string;
  name: string;
  sku_id: string;
  variables: { [key: string]: any };
  featured: boolean;
  language: string;
  sku_ref_id: string;
  sku_type: string;
  config_warnings: LooseObject;
  delete_warnings: LooseObject;
  language_humanized: string;
  selected: boolean;
  // TODO: Remove the below
  display_text: string;
  sub_display_text: string;
  entitlements: TProductEntitlement[];
};

export type TPaywallMenu = {
  id: string;
  config_warnings: LooseObject;
  platform: string;
  product_group: string;
  ordered_items: TMenuItem[];
};

export type TPaywallMedia = {
  id: string;
  content: string | null;
  paywall: string;
  name: string;
};

export type TTemplateIcon = {
  name: string;
  display_name: string;
  ant: TIconName;
  android_material: string;
  ios_sf_symbols_v3_3: string;
};

export type TPaywallMediaPayload = Pick<TPaywallMedia, 'paywall' | 'name'> & {
  content: string | File | RcFile | Blob | null;
};

export type TProductGroup = {
  id: string;
  display_name: string;
  display_order: number;
  default: boolean;
  paywall: string;
  ref: string;
};

export type TProductGroupPayload = Omit<TProductGroup, 'id'>;

export type TDevice = 'phone' | 'tablet' | 'television' | 'desktop';

export type TFormFactorOrientation =
  | 'phone'
  | 'tablet-landscape'
  | 'tablet-portrait'
  | 'tablet-rotateable'
  | 'television'
  | 'desktop';

export type TDeviceOrientation = 'landscape' | 'portrait';

export type TOfferState = 'standard' | 'trial' | 'intro' | 'promo';

export type TPreviewDevice =
  | 'iphone15'
  | 'iphone1415_pro_max'
  | 'iphone1415_pro'
  | 'iphone121314'
  | 'iphone1213_mini'
  | 'iphone1213_pro_max'
  | 'iphone11'
  // | 'iphone_se'
  | 'google_pixel_678'
  | 'google_pixel_8_pro'
  | 'google_pixel_67_pro'
  | 'google_pixel_5'
  // | 'google_pixel_4'
  // | 'google_pixel_3'
  | 'samsung_galaxy_s9'
  | 'samsung_galaxy_s2021'
  | 'samsung_galaxy_s2021_plus'
  | 'samsung_galaxy_s2021_ultra'
  | 'samsung_galaxy_s22'
  | 'samsung_galaxy_s22_ultra'
  | 'desktop_1920x1080'
  // | 'desktop_1366x768'
  | 'desktop_1440x900'
  | 'desktop_1280x1024'
  | 'desktop_1024x768';

export type TPreviewDeviceMeta = {
  type: TDevice;
  os?: 'apple' | 'android';
  brand?: 'samsung' | 'google';
  displayName: string;
  viewportHeight: number;
  viewportWidth: number;
  hasNotch: boolean;
  hasDynamicIsland: boolean;
  builtInHeaderHeight?: number;
  pinholeCameraPosition?: 'center' | 'left';
  safeArea: number;
  statusBarHeight?: number;
  bottomBarHeight?: number;
  middlePadding: number;
  outerPadding: number;
  innerRadius: number;
};
export type TPreviewDeviceMetaAndKey = TPreviewDeviceMeta & {
  name: TPreviewDevice;
};
export const PreviewDeviceMeta: Record<TPreviewDevice, TPreviewDeviceMeta> = {
  desktop_1920x1080: {
    type: 'desktop',
    displayName: 'Desktop (1920x1080)',
    viewportHeight: 1080,
    viewportWidth: 1920,
    hasDynamicIsland: false,
    hasNotch: false,
    safeArea: 0,
    innerRadius: 6,
    middlePadding: 5,
    outerPadding: 8,
  },
  desktop_1440x900: {
    type: 'desktop',
    displayName: 'Laptop Lg (1440x900)',
    viewportHeight: 900,
    viewportWidth: 1440,
    hasDynamicIsland: false,
    hasNotch: false,
    safeArea: 0,
    innerRadius: 6,
    middlePadding: 5,
    outerPadding: 8,
  },
  // desktop_1366x768: {
  //   type: 'desktop',
  //   displayName: '1366x768',
  //   viewportHeight: 768,
  //   viewportWidth: 1366,
  //   hasDynamicIsland: false,
  //   hasNotch: false,
  //   safeArea: 0,
  //   innerRadius: 6,
  //   middlePadding: 5,
  //   outerPadding: 8,
  // },
  desktop_1280x1024: {
    type: 'desktop',
    displayName: 'Laptop Md (1280x1024)',
    viewportHeight: 1024,
    viewportWidth: 1280,
    hasDynamicIsland: false,
    hasNotch: false,
    safeArea: 0,
    innerRadius: 6,
    middlePadding: 5,
    outerPadding: 8,
  },
  desktop_1024x768: {
    type: 'desktop',
    displayName: 'Laptop Sm (1024x768)',
    viewportHeight: 768,
    viewportWidth: 1024,
    hasDynamicIsland: false,
    hasNotch: false,
    safeArea: 0,
    innerRadius: 6,
    middlePadding: 5,
    outerPadding: 8,
  },
  iphone15: {
    type: 'phone',
    os: 'apple',
    displayName: 'iPhone 15',
    viewportHeight: 844,
    viewportWidth: 390,
    hasNotch: false,
    hasDynamicIsland: true,
    safeArea: 40, //TODO
    statusBarHeight: 0,
    innerRadius: 55,
    middlePadding: 9,
    outerPadding: 5,
  },
  iphone1415_pro: {
    type: 'phone',
    os: 'apple',
    displayName: 'iPhone 14/15 Pro',
    viewportHeight: 852,
    viewportWidth: 393,
    hasNotch: false,
    hasDynamicIsland: true,
    safeArea: 40, //TODO,
    statusBarHeight: 0,
    innerRadius: 55,
    middlePadding: 12,
    outerPadding: 5,
  },
  iphone1415_pro_max: {
    type: 'phone',
    os: 'apple',
    displayName: 'iPhone 14/15 Pro Max',
    viewportHeight: 932,
    viewportWidth: 430,
    hasNotch: false,
    hasDynamicIsland: true,
    safeArea: 40, //TODO,
    statusBarHeight: 0,
    innerRadius: 55,
    middlePadding: 12, //TODO
    outerPadding: 5, //TODO
  },
  iphone121314: {
    type: 'phone',
    os: 'apple',
    displayName: 'iPhone 13/14',
    viewportHeight: 844,
    viewportWidth: 390,
    hasNotch: true,
    hasDynamicIsland: false,
    safeArea: 40, //TODO
    statusBarHeight: 0,
    innerRadius: 47,
    middlePadding: 9,
    outerPadding: 5,
  },
  iphone1213_pro_max: {
    type: 'phone',
    os: 'apple',
    displayName: 'iPhone 13 Pro Max',
    viewportHeight: 926,
    viewportWidth: 428,
    hasNotch: true,
    hasDynamicIsland: false,
    safeArea: 40, //TODO
    statusBarHeight: 0,
    innerRadius: 47,
    middlePadding: 9,
    outerPadding: 5,
  },
  iphone1213_mini: {
    type: 'phone',
    os: 'apple',
    displayName: 'iPhone 12/13 Mini',
    viewportHeight: 812,
    viewportWidth: 375,
    hasNotch: true,
    hasDynamicIsland: false,
    safeArea: 40, //TODO
    statusBarHeight: 0,
    innerRadius: 44,
    middlePadding: 9,
    outerPadding: 5,
  },
  iphone11: {
    type: 'phone',
    os: 'apple',
    displayName: 'iPhone 11',
    viewportHeight: 896,
    viewportWidth: 414,
    hasNotch: true,
    hasDynamicIsland: false,
    safeArea: 40, //TODO
    statusBarHeight: 0,
    innerRadius: 41.5,
    middlePadding: 9, //TODO
    outerPadding: 5, //TODO
  },
  // iphone_se: {
  //   type: 'phone',
  //   displayName: 'iPhone SE',
  //   viewportHeight: 667,
  //   viewportWidth: 375,
  //   hasNotch: true,
  //   safeArea: 40, //TODO
  //   innerRadius: 44, //TODO
  //   middlePadding: 9, //TODO
  //   outerPadding: 5, //TODO
  // },
  samsung_galaxy_s22: {
    type: 'phone',
    os: 'android',
    brand: 'samsung',
    displayName: 'Samsung Galaxy S22',
    viewportHeight: 780,
    viewportWidth: 360,
    hasNotch: false,
    hasDynamicIsland: false,
    pinholeCameraPosition: 'center',
    safeArea: 0,
    statusBarHeight: 27,
    bottomBarHeight: 48,
    innerRadius: 34,
    middlePadding: 8,
    outerPadding: 8,
  },
  samsung_galaxy_s22_ultra: {
    type: 'phone',
    os: 'android',
    brand: 'samsung',
    displayName: 'Samsung Galaxy S22 Ultra',
    viewportHeight: 824,
    viewportWidth: 384,
    hasNotch: false,
    hasDynamicIsland: false,
    pinholeCameraPosition: 'center',
    safeArea: 0,
    statusBarHeight: 27,
    innerRadius: 5,
    middlePadding: 7,
    outerPadding: 6,
  },
  samsung_galaxy_s2021: {
    type: 'phone',
    os: 'android',
    brand: 'samsung',
    displayName: 'Samsung Galaxy S20/S21',
    viewportHeight: 800,
    viewportWidth: 360,
    hasNotch: false,
    hasDynamicIsland: false,
    pinholeCameraPosition: 'center',
    safeArea: 0,
    statusBarHeight: 27,
    innerRadius: 33,
    middlePadding: 6,
    outerPadding: 8,
  },
  samsung_galaxy_s2021_plus: {
    type: 'phone',
    os: 'android',
    brand: 'samsung',
    displayName: 'Samsung Galaxy S20/S21 Plus',
    viewportHeight: 854,
    viewportWidth: 384,
    hasNotch: false,
    hasDynamicIsland: false,
    pinholeCameraPosition: 'center',
    safeArea: 0,
    statusBarHeight: 27,
    innerRadius: 33,
    middlePadding: 6,
    outerPadding: 8,
  },
  samsung_galaxy_s2021_ultra: {
    type: 'phone',
    os: 'android',
    brand: 'samsung',
    displayName: 'Samsung Galaxy S20/S21 Ultra',
    viewportHeight: 915,
    viewportWidth: 412,
    hasNotch: false,
    hasDynamicIsland: false,
    pinholeCameraPosition: 'center',
    safeArea: 0,
    statusBarHeight: 27,
    innerRadius: 39,
    middlePadding: 6,
    outerPadding: 8,
  },
  samsung_galaxy_s9: {
    type: 'phone',
    os: 'android',
    brand: 'samsung',
    displayName: 'Samsung Galaxy S9',
    viewportHeight: 740,
    viewportWidth: 360,
    hasNotch: false,
    hasDynamicIsland: false,
    safeArea: 0,
    statusBarHeight: 27, //TODO
    innerRadius: 33,
    middlePadding: 6,
    outerPadding: 8,
  },
  google_pixel_678: {
    type: 'phone',
    os: 'android',
    brand: 'google',
    displayName: 'Google Pixel 7/8',
    viewportHeight: 915,
    viewportWidth: 412,
    hasNotch: false,
    hasDynamicIsland: false,
    pinholeCameraPosition: 'center',
    safeArea: 0,
    statusBarHeight: 34,
    innerRadius: 18,
    middlePadding: 12,
    outerPadding: 8,
  },
  google_pixel_8_pro: {
    type: 'phone',
    os: 'android',
    brand: 'google',
    displayName: 'Google Pixel 8 Pro',
    viewportHeight: 998,
    viewportWidth: 448,
    hasNotch: false,
    hasDynamicIsland: false,
    pinholeCameraPosition: 'center',
    safeArea: 0,
    statusBarHeight: 39, //TODO
    innerRadius: 37,
    middlePadding: 12,
    outerPadding: 8,
  },
  google_pixel_67_pro: {
    type: 'phone',
    os: 'android',
    brand: 'google',
    displayName: 'Google Pixel 6/7 Pro',
    viewportHeight: 892,
    viewportWidth: 412,
    hasNotch: false,
    hasDynamicIsland: false,
    pinholeCameraPosition: 'center',
    safeArea: 0,
    statusBarHeight: 39,
    innerRadius: 18,
    middlePadding: 8,
    outerPadding: 8,
  },
  google_pixel_5: {
    type: 'phone',
    os: 'android',
    brand: 'google',
    displayName: 'Google Pixel 5',
    viewportHeight: 851,
    viewportWidth: 393,
    hasNotch: false,
    hasDynamicIsland: false,
    pinholeCameraPosition: 'left',
    safeArea: 0,
    statusBarHeight: 44, //TODO
    innerRadius: 37,
    middlePadding: 9,
    outerPadding: 14,
  },
  // google_pixel_4: {
  //   type: 'phone',
  //   os: 'android',
  //   displayName: 'Google Pixel 4',
  //   viewportHeight: 830,
  //   viewportWidth: 393,
  //   hasNotch: false,
  //   hasDynamicIsland: false,
  //   safeArea: 0,
  //   statusBarHeight: 27, //TOOD
  //   innerRadius: 37,
  //   middlePadding: 8,
  //   outerPadding: 12,
  // },
  // google_pixel_3: {
  //   type: 'phone',
  //   os: 'android',
  //   displayName: 'Google Pixel 3',
  //   viewportHeight: 786,
  //   viewportWidth: 393,
  //   hasNotch: false,
  //   hasDynamicIsland: false,
  //   builtInHeaderHeight: 48,
  //   safeArea: 0,
  //   statusBarHeight: 0, //TODO
  //   bottomBarHeight: 48,
  //   innerRadius: 37,
  //   middlePadding: 8,
  //   outerPadding: 12,
  // },
};
