import React, { useMemo } from 'react';

import { TagsOutlined } from '@ant-design/icons';
import { createSelector } from '@reduxjs/toolkit';
import { Form, Select, Space } from 'antd';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { deconstructVariable } from 'src/utils/paywall';
import { namiSmoke } from 'src/variables';

import type { FieldObject } from '../../../utils/formFieldBuilding';
import InputLabel from './InputLabel';

type ProductVariableSelectorProps = Omit<FieldObject, 'variable'> & {
  defaultValue?: string | number;
  onChange: (value: any) => void;
  label: string;
  controlled?: boolean;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
  conditionAttribute: boolean;
};

const selector = createSelector(
  [
    ({ paywallBuilder }: RootState) =>
      paywallBuilder.paywall?.template['ui.productSettings']?.variablesList,
  ],
  (productVariables) => productVariables
);

export default function ProductVariableSelector({
  defaultValue,
  controlled = false,
  componentLocation,
  conditionAttribute = false,
  ...field
}: ProductVariableSelectorProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const itemProps = {
    name: controlled ? undefined : field.variable,
    label: <InputLabel {...field} />,
  };
  const productVariables = useAppSelector(selector);

  const variableValue = useMemo(() => {
    return deconstructVariable(field.variable || '', false, true);
  }, [field.variable]);

  const selectOptions = (productVariables || []).map((variable) => {
    return {
      value: variable.variable,
      label: variable.display_name,
    };
  });

  return (
    <Form.Item
      {...itemProps}
      label={
        <Space direction="horizontal" size={5}>
          {field.name || 'Product Field'}
          <TagsOutlined style={{ fontSize: 14 }} />
        </Space>
      }
      tooltip={{
        title: (
          <>
            <IconActionButton
              type="text"
              size="small"
              icon={<TagsOutlined style={{ fontSize: '13px' }} />}
              style={{ color: namiSmoke }}
              onClick={() => {
                actions.setEditingMenuItem('products');
                actions.setEditingProductMenuTabItem('fields');
              }}
            >
              Edit Product Fields
            </IconActionButton>
          </>
        ),
      }}
    >
      <Select
        value={variableValue}
        placeholder="Select product field"
        options={selectOptions}
        onChange={(value: string) => handleChange(value)}
      />
    </Form.Item>
  );

  function handleChange(value: string) {
    if (!componentLocation) {
      console.warn(
        `Cannot update product text to ${value}, component location is unknown`
      );
      return;
    }
    if (conditionAttribute) {
      const newAttributes =
        field.variable === 'sku.screenreaderText'
          ? { screenreaderText: `\${sku.${value}}` }
          : {
              text: `\${sku.${value}}`,
            };
      actions.updateComponentConditionAttributes({
        idLocation: componentLocation,
        assertionIndex: field.currentAssertionIndex || 0,
        attributes: newAttributes,
      });
    } else {
      actions.updateComponentProductText({
        location: componentLocation,
        value: `\${sku.${value}}`,
      });
    }
  }
}
