import { TimeDimensionGranularity } from '@cubejs-client/core';
import { Serie } from '@nivo/line';
import moment from 'moment';
import {
  DataKey,
  DataKeyFormat,
  DataKeys,
  MetricDataType,
  SegmentData,
  TData,
} from 'src/api/types/analytics.types';
import { TCampaignLabel, TCampaignRule } from 'src/api/types/campaign.types';
import { PaywallType } from 'src/api/types/paywall.types';
import { TProduct } from 'src/api/types/sku.types';
import countries from 'src/countries';
import languages from 'src/languages';
import store from 'src/redux';

type TUnitKey = keyof typeof PERIOD_UNITS;
type TDurationMatch = [string, string, TUnitKey] | undefined;

export const PLATFORMS = {
  google: 'Google',
  apple: 'Apple',
  amazon_iap: 'Amazon',
  roku_pay: 'Roku',
  web: 'Web',
};

const PERIOD_UNITS = {
  H: 'hour',
  D: 'day',
  W: 'week',
  M: 'month',
  Q: 'quarter',
  Y: 'year',
};

export function formatNumber(number: number): string {
  if (isNaN(number) || number < 1000) return '' + number;
  if (number < 1000000) return number / 1000 + 'K';
  if (number < 10000000) return (number / 1000000).toFixed(2) + 'M';
  if (number < 1000000000) return Math.round(number / 1000000) + 'M';
  if (number < 1000000000000) return Math.round(number / 1000000000) + 'B';
  return '1T+';
}

export function formatPercentageNumber(number: number): string {
  return '' + (number * 100).toFixed(1);
}

export function formatPercentageString(number: number): string {
  return '' + Math.round(number * 100) + '%';
}

export function returnFormatForNivo(format: MetricDataType): string {
  if (format === 'CURRENCY') return '<-$,.2f';
  if (format === 'INTEGER') return ' <-,.6~f';
  if (format === 'PERCENT') return ' <-0.5~%';
  return ' <-.0';
}

export function returnDateFormat(resolution: TimeDimensionGranularity): string {
  if (resolution === 'hour') return 'MMM D ha';
  if (resolution === 'day') return 'MMM D';
  if (resolution === 'week') return "MMM D [']YY";
  if (resolution === 'month') return "MMM [']YY";
  if (resolution === 'quarter') return "[Q]Q [']YY";
  if (resolution === 'year') return 'YYYY';
  return "MMM D [']YY";
}

export function getLabel(
  preferredFormat: DataKeyFormat,
  dataObject: TData,
  segment: SegmentData | null
): string {
  const titleParts = dataObject.title.split(',');
  if (segment && titleParts.length === 1) return 'Unknown';
  if (!segment) return preferredFormat.display_name;
  return segment.labelTransforms.reduce(
    (value, func) => func(value),
    titleParts[0]
  );
}

export function capitalize(value: string): string {
  value = String(value);
  if (!value.length) return '';
  return value[0].toUpperCase() + value.substring(1);
}

export function formatSegmentPlatform(value: keyof typeof PLATFORMS): string {
  return PLATFORMS[value] || value;
}

export function formatDuration(value: string): string {
  const durationPieces = value.match(/P([0-9]+)([HDWMQY])/) as TDurationMatch;
  if (!durationPieces) return value;
  const [period, unitKey] = [durationPieces[1], durationPieces[2]];
  return `${period} ${buildDurationUnit(unitKey, +period > 1)}`;
}

function buildDurationUnit(unitKey: TUnitKey, isPlural: boolean): string {
  if (!(unitKey in PERIOD_UNITS)) return unitKey;
  return `${PERIOD_UNITS[unitKey]}${isPlural ? 's' : ''}`;
}

//TODO - call off to API to get languages instead, or get them from App context?
export function translateLanguageCode(value: keyof typeof languages): string {
  return value in languages ? languages[value] : value;
}

export function translateCountryCode(value: keyof typeof countries): string {
  return value in countries ? countries[value] : value;
}

export function translateCampaignLabel(value: string): string {
  const placement = store.getState().analytic.placements[value];
  return placement ? placement.value : value;
}

export function translateCampaignLabelToPlacement(
  value: string
): TCampaignLabel | null {
  const placement = store.getState().analytic.placements[value];
  return placement ? placement : null;
}

export function translateCampaignId(value: string): string {
  const campaign = store.getState().analytic.campaigns[value];
  return campaign ? campaign.name : value;
}

export function translateCampaignIdToCampaign(
  value: string
): TCampaignRule | null {
  const campaign = store.getState().analytic.campaigns[value];
  return campaign ? campaign : null;
}

export function translateProductID(value: string): string {
  const product = store.getState().analytic.products[value];
  return product ? product.platform_name + ' - ' + product.name : value;
}

export function translateProductIDToProduct(value: string): TProduct | null {
  const product = store.getState().analytic.products[value];
  return product ? product : null;
}

export function translatePaywallId(value: string): string {
  const paywall = store.getState().analytic.paywalls[value];
  return paywall ? paywall.name : value;
}

export function translatePaywallIdToPaywall(value: string): PaywallType | null {
  const paywall = store.getState().analytic.paywalls[value];
  return paywall ? paywall : null;
}

export function parseChartData(
  rawData: TData[],
  chartKeys: Array<DataKey>,
  segment: SegmentData | null,
  dateFormat: string
): Serie[] {
  const result: Serie[] = [];

  for (const dataObject of rawData) {
    const keyParts = dataObject.key.split(',') as DataKey[];
    const key = segment && keyParts.length > 1 ? keyParts[1] : keyParts[0];
    if (!chartKeys.includes(key)) continue;
    result.push({
      id: getLabel(DataKeys[key], dataObject, segment),
      data: dataObject.series.map(({ x, value }) => ({
        x: moment(x).format(dateFormat),
        y: value,
      })),
    });
  }

  return result;
}
