import React, { useMemo } from 'react';

import {
  TRepeatingList,
  TTestObject,
} from 'src/api/types/paywallTemplate.types';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { interpolate } from 'src/utils/interpolation';
import { TSemverObj } from 'src/utils/parsing';
import styled from 'styled-components';

import TemplateComponent from '../TemplateComponent';
import { transition } from '../css';
import { testObjectMatches } from '../utils';
import { ContainerWrapper } from './Container';
import HoverTag from './HoverTag';

type RepeatingGridProps = {
  component: TRepeatingList;
  minSDKVersion: TSemverObj;
};

const Wrapper = styled(ContainerWrapper)`
  ${transition()}
  width: 100%;
  display: flex;
  overflow: auto;
  scrollbar-width: none;
  justify-content: start;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  > div {
    flex: 0 0 auto;
  }
`;

export default function RepeatingGrid({
  component: { loopVariable, loopSource, ...component },
  minSDKVersion,
}: RepeatingGridProps) {
  const customObject = useAppSelector(
    (state) => state.paywallBuilder.launch.customObject
  );

  const isValid =
    loopSource && Array.isArray(loopSource) && typeof loopVariable === 'string';

  const blocksArray: any[] = useMemo(() => {
    if (!isValid) return [];
    if (Array.isArray(loopSource)) return loopSource;
    return customObject ? customObject.loopSource : [];
  }, [isValid, loopSource, customObject]);

  const filteredBlocksArray = useMemo(() => {
    if (
      !component.loopSourceConditions ||
      !component.loopSourceConditions.length
    )
      return blocksArray;
    return blocksArray.reduce((output, block) => {
      const newBlock: any = Object.keys(block).reduce((output, key) => {
        return {
          ...output,
          [`\${block.${key}}`]: block[key],
        };
      }, {});
      const testObjectsReplaced = (component.loopSourceConditions || []).reduce(
        (output, condition) => {
          return [
            ...output,
            {
              ...condition,
              value: newBlock.hasOwnProperty(condition.value)
                ? newBlock[condition.value]
                : condition.value,
            },
          ];
        },
        [] as TTestObject[]
      );
      if (testObjectsReplaced.every(testObjectMatches)) {
        return [...output, block];
      }
      return output;
    }, [] as any[]);
  }, [blocksArray, component.loopSourceConditions]);

  const parsedBlocks = useMemo(() => {
    if (!isValid) return [];
    return filteredBlocksArray.map((block: any) => {
      const replacements = { [loopVariable]: block };
      return interpolate(component.components, replacements).map((child, i) => (
        <TemplateComponent
          key={`block-${i}`}
          component={child}
          groupId={null}
          minSDKVersion={minSDKVersion}
        />
      ));
    }, []);
  }, [
    component.components,
    isValid,
    minSDKVersion,
    filteredBlocksArray,
    loopVariable,
  ]);

  return (
    <HoverTag
      title={component.title || 'Repeating Grid'}
      namiComponentType={component.namiComponentType}
      id={component.id}
    >
      <Wrapper component={component} minSDKVersion={minSDKVersion}>
        {parsedBlocks}
      </Wrapper>
    </HoverTag>
  );
}
