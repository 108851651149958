import React, { Fragment, useLayoutEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import IPad from '../IPad/IPad';
import IPhone from '../IPhone/IPhone';
import styles from './PreviewPaywall.module.less';

const StyledIPhone = styled(IPhone)`
  margin: 0 auto;
`;

const PreviewPaywall = ({
  title,
  body,
  imagePhone,
  imageTablet,
  purchaseTerms,
  products,
  restore,
  signIn,
  showOverlay,
  sizeOverlay,
  skusInOverlay,
  legal,
  paywallStyles,
}) => {
  const [type, setType] = useState(false);
  const [height, setHeight] = useState(0);
  const [bottom, setBottom] = useState(0);
  let wrapper = useRef(null);
  let header = useRef(null);
  let overlay = useRef(null);
  let skus = useRef(null);

  const percentage = (number, percent) => {
    return (number / 100) * percent;
  };

  useLayoutEffect(() => {
    const wrapperHeight = wrapper.current?.clientHeight;
    if (sizeOverlay === 'L') {
      setHeight(Math.round(percentage(wrapperHeight, 60)));
    } else if (sizeOverlay === 'M') {
      setHeight(Math.round(percentage(wrapperHeight, 40)));
    } else if (sizeOverlay === 'S') {
      setHeight(Math.round(percentage(wrapperHeight, 20)));
    }
  }, [
    title,
    body,
    showOverlay,
    sizeOverlay,
    skusInOverlay,
    paywallStyles,
    type,
  ]);

  useLayoutEffect(() => {
    const wrapperHeight = wrapper.current?.clientHeight;
    const headerHeight = header.current?.clientHeight;
    const overlayHeight = overlay.current?.clientHeight;
    const skusHeight = skus.current?.clientHeight
      ? skus.current?.clientHeight
      : 0;

    let pushDownOverlay = type
      ? wrapperHeight - headerHeight <
        overlayHeight + skusHeight + Math.round(percentage(wrapperHeight, 20))
      : wrapperHeight - headerHeight <
        overlayHeight + skusHeight + Math.round(percentage(wrapperHeight, 10));
    let result = type
      ? wrapperHeight -
        headerHeight -
        skusHeight -
        Math.round(percentage(wrapperHeight, 20))
      : wrapperHeight -
        headerHeight -
        skusHeight -
        Math.round(percentage(wrapperHeight, 10));

    if (pushDownOverlay) {
      if (result < 0) {
        setHeight(0);
        setBottom(result);
      } else {
        setBottom(0);
        setHeight(result);
      }
    }
  }, [
    title,
    body,
    showOverlay,
    sizeOverlay,
    skusInOverlay,
    paywallStyles,
    type,
    height,
  ]);

  const hasShadowRadius = (shadowRadius, shadowColor) => {
    if (shadowRadius !== 0) {
      return `2px 2px ${Math.ceil(shadowRadius / 2)}px ${shadowColor}`;
    } else {
      return '';
    }
  };

  const contentStyle = {
    height: height,
    background: showOverlay ? paywallStyles.bottom_overlay_color : '',
    borderRadius: Number.isNaN(
      parseFloat(paywallStyles.bottom_overlay_corner_radius)
    )
      ? 0
      : parseFloat(paywallStyles.bottom_overlay_corner_radius),
  };

  const ButtonProduct = (product) => {
    if (product.featured) {
      return {
        background: paywallStyles.featured_skus_button_color,
        color: paywallStyles.featured_skus_button_text_color,
      };
    } else {
      return {
        background: paywallStyles.sku_button_color,
        color: paywallStyles.sku_button_text_color,
      };
    }
  };

  const ButtonSignIn = {
    color: paywallStyles.signin_button_text_color,
    fontSize: Number.isNaN(parseFloat(paywallStyles.signin_button_font_size))
      ? 0
      : parseFloat(paywallStyles.signin_button_font_size),
    textShadow: hasShadowRadius(
      paywallStyles.signin_button_shadow_radius,
      paywallStyles.signin_button_shadow_color
    ),
  };

  const ButtonRestorePurchase = {
    color: paywallStyles.restore_button_text_color,
    fontSize: Number.isNaN(parseFloat(paywallStyles.restore_button_font_size))
      ? 0
      : parseFloat(paywallStyles.restore_button_font_size),
    textShadow: hasShadowRadius(
      paywallStyles.restore_button_shadow_radius,
      paywallStyles.restore_button_shadow_color
    ),
  };

  const Title = {
    color: paywallStyles.title_text_color,
    fontSize: Number.isNaN(parseFloat(paywallStyles.title_font_size))
      ? 0
      : parseFloat(paywallStyles.title_font_size),
    textShadow: hasShadowRadius(
      paywallStyles.title_shadow_radius,
      paywallStyles.title_shadow_color
    ),
  };

  const Body = {
    color: paywallStyles.body_text_color,
    fontSize: Number.isNaN(parseFloat(paywallStyles.body_font_size))
      ? 0
      : parseFloat(paywallStyles.body_font_size),
    textShadow: hasShadowRadius(
      paywallStyles.body_shadow_radius,
      paywallStyles.body_shadow_color
    ),
  };

  const Close = {
    color: paywallStyles.close_button_text_color,
    fontSize: Number.isNaN(parseFloat(paywallStyles.close_button_font_size))
      ? 0
      : parseFloat(paywallStyles.close_button_font_size),
    textShadow: hasShadowRadius(
      paywallStyles.close_button_shadow_radius,
      paywallStyles.close_button_shadow_color
    ),
  };

  const PurchaseTerms = {
    color: paywallStyles.purchase_terms_text_color,
    fontSize: Number.isNaN(parseFloat(paywallStyles.purchase_terms_font_size))
      ? 0
      : parseFloat(paywallStyles.purchase_terms_font_size),
    textShadow: hasShadowRadius(
      paywallStyles.purchase_terms_shadow_radius,
      paywallStyles.purchase_terms_shadow_color
    ),
    whiteSpace: 'pre-wrap',
  };

  const SubDisplayText = {
    color: paywallStyles.sku_sub_display_text_color,
    textShadow: hasShadowRadius(
      paywallStyles.sku_sub_display_text_shadow_radius,
      paywallStyles.sku_sub_display_text_shadow_color
    ),
    whiteSpace: 'pre-wrap',
  };

  /* const PurchaseTermsLink = {
    color: paywallStyles.terms_link_color,
  }; */

  const imagePhoneStyle = {
    background: `url('${imagePhone}') 0% 0% / 100% 100% no-repeat, 0% 0% / cover ${paywallStyles.background_color}`,
  };

  const imageTabletStyle = {
    background: `url('${imageTablet}') 0% 0% / 100% 84% no-repeat, 0% 0% / cover ${paywallStyles.background_color}`,
  };

  const Products = () => {
    return (
      <div>
        {products &&
          !!products.length &&
          products.map((product, index) => (
            <div key={index}>
              <Button
                className={styles.button}
                style={ButtonProduct(product)}
                fluid
              >
                {product.display_text}
              </Button>
              <span className={styles.purchaseTerms} style={SubDisplayText}>
                {product.sub_display_text}
              </span>
            </div>
          ))}
      </div>
    );
  };

  const Header = (className, headerStyle) => (
    <div ref={wrapper} className={className} style={headerStyle}>
      <div ref={header}>
        <div className={styles.close} style={Close}>
          Close
        </div>
        <h1 className={styles.title} style={Title}>
          {title}
        </h1>
        {body && (
          <p className={styles.body} style={Body}>
            {body}
          </p>
        )}
      </div>
    </div>
  );

  const Content = (className) => (
    <div>
      <div style={{ position: 'absolute', bottom: bottom, left: 0, right: 0 }}>
        {!skusInOverlay && (
          <div ref={skus} style={{ margin: 20 }}>
            {Products()}
          </div>
        )}

        <div ref={overlay} className={className} style={contentStyle}>
          {skusInOverlay && Products()}
          {signIn && (
            <div className={styles.transparentButton} style={ButtonSignIn}>
              Sign In
            </div>
          )}
          {restore && (
            <div
              className={styles.transparentButton}
              style={ButtonRestorePurchase}
            >
              Restore Purchase
            </div>
          )}
          <p className={styles.purchaseTerms} style={PurchaseTerms}>
            {purchaseTerms}
          </p>
          {legal && (
            <p className={styles.purchaseTerms} style={PurchaseTerms}>
              {legal}
            </p>
          )}
        </div>
      </div>
    </div>
  );

  const Device = (Device) => {
    let HeaderClassName = type ? styles.headerIPad : styles.headerIPhone;
    let HeaderStyle = type ? imageTabletStyle : imagePhoneStyle;
    let ContentClassName = type ? styles.contentIPad : styles.contentIPhone;
    return (
      <Device scale={0.7}>
        {Header(HeaderClassName, HeaderStyle)}
        {Content(ContentClassName)}
      </Device>
    );
  };

  return (
    <Fragment>
      {type ? Device(IPad) : Device(StyledIPhone)}
      <Button.Group size="tiny">
        <Button active={!type} attached onClick={() => setType(false)}>
          <Icon name="mobile alternate" /> Phone
        </Button>
        <Button active={type} attached onClick={() => setType(true)}>
          <Icon name="tablet alternate" /> Tablet
        </Button>
      </Button.Group>
    </Fragment>
  );
};

PreviewPaywall.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  mobileImage: PropTypes.string,
  tabletImage: PropTypes.string,
  products: PropTypes.array,
  purchaseTerms: PropTypes.string,
  privacyPolicy: PropTypes.string,
  termsOfUse: PropTypes.string,
  paywallStyles: PropTypes.object,
};

export default PreviewPaywall;
